import React, { useMemo, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import ReactPaginate from "react-paginate";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import bnb from "../../../images/coins/bnb.svg";
import { findUpper } from "../../../utils/Utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt, Star } from "@fortawesome/free-solid-svg-icons";
import PdfViewer from "./PdfViewer";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { Label, Input } from "reactstrap";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { makeGetRequest, getHeaders } from "../../../service/api";
import { Tabs } from "react-simple-tabs-component";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetailsFromToken } from "../../../service/auth";
import { makePostRequest, imageUrl } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import { S3_BUCKET_URL } from "../../../constants";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { get } from "react-hook-form";
import DatePicker from "react-datepicker";

function ViewResource() {
  // console.log("props.location", props.location)
  // let passKey = localStorage.getItem('passKey');
  //   console.log(passKey, "passKey");
  // let passKey = window.passKey;
  // console.log(passKey, "passKey");
  const urlParams = new URLSearchParams(window.location.search);
  const passKey = urlParams.get("passKey");

  let history = useHistory();

  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [selectedfromdatepurchase, setSelectedFromDatePurchase] = useState(null);
  const [selectedtodatepurchase, setSelectedToDatePurchase] = useState(null);
  const[formDateErrorPurchase,setformDateErrorPurchase]=useState("")
  const[toDateErrorPurchase,settoDateErrorPurchase]=useState("")
    
  const [selectedfromdatereview, setSelectedFromDateReview] = useState(null);
  const [selectedtodatereview, setSelectedToDateReview] = useState(null);
  const [fromdateErrorReview, setfromDateErrorReview] = useState("");
  const [todateErrorReview, settoDateErrorReview] = useState("");
  
  const [selectedfromdateshared, setSelectedFromDateShared] = useState(null);
  const [selectedtodateshared, setSelectedToDateShared] = useState(null);
  const [fromdateErrorshared, setfromDateErrorShared] = useState("");
  const [todateErrorshared, settoDateErrorShared] = useState("");
  
  const [selectedfromdatesaved, setSelectedFromDateSaved] = useState(null);
  const [selectedtodatesaved, setSelectedToDateSaved] = useState(null);
  const [fromdateErrorsaved, setfromDateErrorSaved] = useState("");
  const [todateErrorsaved, settoDateErrorSaved] = useState("");
  
  const [selectedfromdatelike, setSelectedFromDateLike] = useState(null);
  const [selectedtodatelike, setSelectedToDateLike] = useState(null);
  const [fromdateErrorlike, setfromDateErrorLike] = useState("");
  const [todateErrorlike, settoDateErrorLike] = useState("");
  
  const [selectedfromdatecomment, setSelectedFromDateComment] = useState(null);
  const [selectedtodatecomment, setSelectedToDateComment] = useState(null);
  const [fromdateErrorcomment, setfromDateErrorComment] = useState("");
  const [todateErrorcomment, settoDateErrorComment] = useState("");
  
  const [selectedfromdateoffensive, setSelectedFromDateOffensive] = useState(null);
  const [selectedtodateoffensive, setSelectedToDateOffensive] = useState(null);
  const [fromdateErroroffensive, setFromDateErrorOffensive] = useState("");
  const [todateErroroffensive, setToDateErrorOffensive] = useState("");
  
  
  const [selectedfromdatementoring, setSelectedFromDateMentoring] = useState(null);
  const [selectedtodatementoring, setSelectedToDateMentoring] = useState(null);
  const [fromdateErrormentoring, setFromDateErrorMentoring] = useState("");
  const [todateErrormentoring, setToDateErrorMentoring] = useState("");
  
  const [selectedfromdateGroupResource, setSelectedFromDateGroupResource] = useState(null);
  const [selectedtodateGroupResource, setSelectedToDateGroupResource] = useState(null);
  const [fromdateErrorGroupResource, setFromDateErrorGroupResource] = useState("");
  const [todateErrorGroupResource, setToDateErrorGroupResource] = useState("");
  
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [selectchild, setSelectChild] = useState("");
  const [sharedwith, setSharedWith] = useState("");
  const [friendList, setFriendList] = useState([]);
  const [grouplist, setGroupList] = useState([]);
  const [groupListResource, setGroupListResource] = useState([]);

  const [selectedfromdateResourceGetInfo, setSelectedFromDateResourceGetInfo] = useState(null);
  const [selectedtodateResourceGetInfo, setSelectedToDateResourceGetInfo] = useState(null);
  const [fromdateErrorResourceGetInfo, setFromDateErrorResourceGetInfo] = useState("");
  const [todateErrorResourceGetInfo, setToDateErrorResourceGetInfo] = useState("");
  

  const [selectedfromdateResourceEnrol, setSelectedFromDateResourceEnrol] = useState("");
  const [selectedtodateResourceEnrol, setSelectedToDateResourceEnrol] = useState("");
  const [selectedfromdateResourceSignup, setSelectedFromDateResourceSignup] = useState("");
  const [selectedtodateResourceSignup, setSelectedToDateResourceSignup] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

  let tabno = sessionStorage.getItem("tab");

  const params = useParams();
  let resource_id = params.id;
  let { id } = useParams();
  let star = [0.5, 1.5, 2.5, 3.5, 4.5];
  const [currentPage, setCurrentPage] = useState(1);

  const [resourceData, setResourceData] = useState("");
  const [purchaseData, setPurchaseData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [savechilddata, setsavechilddata] = useState([]);
  const [likeData, setLikeData] = useState([]);
  const [mentoringData, setMentoringData] = useState([]);
  const [sharedData, setSharedData] = useState([]);
  const [offensiveData, setOffensiveData] = useState([]);
  const [childdata, setchilddata] = useState({});
  const [getAllFacility, setGetAllFacility] = useState([]);
  const [getAllFunding, setGetAllFunding] = useState([]);
  const [expressionTextError, setErrorExpressionText] = useState("");
  const [selectedFacility, setSelectedFacility] = useState({});
  const [selectedFunding, setSelectedFunding] = useState({});

  const [name, setName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorname, setErrorName] = useState("");
  const [switchState, setSwitchState] = useState("");

  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [childReviews, setChildReviews] = useState("");
  const [resourceContent, setResourceContent] = useState(null);
  const [isPayOpen, setPayOpen] = useState(false);
  const [isSaveOpen, setSaveOpen] = useState(false);
  const [isOffensiveOpen, setOffensiveOpen] = useState(false);
  const [isMentoringOpen, setMentoringOpen] = useState(false);
  const [isSharedOpen, setSharedOpen] = useState(false);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [offensiveDetails, setOffensiveDetails] = useState("");
  const [savedDetails, setSavedDetails] = useState("");
  const [mentoringDetails, setMentoringDetails] = useState("");
  const [selectedFor, setSelectedFor] = useState("");
  const [uploadContentName, setUploadContentName] = useState("");
  const [contentPreview, setContentPreview] = useState({
    imgurl: "",
    imgtype: "",
  });
  const [isVisible, setVisible] = useState(false);

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const paymentModal = () => {
    setPayOpen(!isPayOpen);
  };
  const saveModal = () => {
    setSaveOpen(!isSaveOpen);
  };

  const offensiveModal = () => {
    setOffensiveOpen(!isOffensiveOpen);
  };
  const mentoringModal = () => {
    setMentoringOpen(!isMentoringOpen);
  };

  const shareModal = () => {
    setSharedOpen(!isSharedOpen);
  };
  const setTabno = () => {
    // setLoader(true);

    // console.log("on page load tab no",tabno)
    if (tabno == "") {
      setVerticalIconTab("1");
    }
    // else {
    //   setVerticalIconTab(tabno)
    // }
    // setLoader(false);
  };

  function getTime(time) {
    let [h, m] = time.split(":");

    let res, prefix;
    let date = new Date();
    if (h > 12) {
      h = h - 12;
      prefix = "PM";
    } else {
      prefix = "AM";
    }
    date.setHours(h, m, 0);
    date.toString();
    if (date.getHours() < 10 && date.getMinutes() < 10) res = `0${date.getHours()}:0${date.getMinutes()}`;
    else if (date.getHours() < 10 && date.getMinutes() > 10) res = `0${date.getHours()}:${date.getMinutes()}`;
    else if (date.getMinutes() < 10 && date.getHours() > 10) res = `${date.getHours()}:0${date.getMinutes()}`;
    else res = `${date.getHours()}:${date.getMinutes()}`;
    res = res + " " + prefix;
    return res;
  }

  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"];
  const purchasecolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage1 == 1 ? index + 1 : (currentPage1 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Buyer Name",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0)?.toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0)?.toUpperCase() + row?.tbl_user?.lastName?.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.tbl_user.id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Purchase Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        minWidth: "40px",
        style: { minWidth: "40px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },

      {
        name: "Payment Details",
        selector: (row) => row.payment_id,
        minWidth: "40px",
        style: { minWidth: "40px" },
        grow: 2,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showpaymentdetails(row.tbl_payment_history);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [purchaseData]);
  const sharedcolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage2 == 1 ? index + 1 : (currentPage2 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Shared By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0)?.toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0)?.toUpperCase() + row?.tbl_user?.lastName?.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.user_id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },

      {
        name: "Shared Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        minWidth: "50px",
        style: { minWidth: "50px" },
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Shared With",
        selector: (row) => row.shared_with,
        minWidth: "150px",
        style: { minWidth: "150px" },
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {row.shared_with.indexOf("1,2,3") >= 0 ||
                row.shared_with.indexOf("1,3,2") >= 0 ||
                row.shared_with.indexOf("3,2,1") >= 0 ||
                row.shared_with.indexOf("3,1,2") >= 0 ||
                row.shared_with.indexOf("2,1,3") >= 0 ||
                row.shared_with.indexOf("2,3,1") >= 0
                  ? "Matched Community,Friends,Groups"
                  : row.shared_with.indexOf("1,2") >= 0 || row.shared_with.indexOf("2,1") >= 0
                  ? "Matched Community,Friends"
                  : row.shared_with.indexOf("1,3") >= 0 || row.shared_with.indexOf("3,1") >= 0
                  ? "Matched Community,Groups"
                  : row.shared_with.indexOf("2,3") >= 0 || row.shared_with.indexOf("3,2") >= 0
                  ? "Friends,Groups"
                  : row.shared_with.indexOf("1") >= 0
                  ? "Matched Community"
                  : row.shared_with.indexOf("2") >= 0
                  ? "Friends"
                  : row.shared_with.indexOf("3") >= 0
                  ? "groups"
                  : ""}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "View",
        selector: (row) => row.resource_id,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showshareddetails(row.resource_id, row.user_id, row.id);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [sharedData]);
  const commentcolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage3 == 1 ? index + 1 : (currentPage3 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Commented By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0)?.toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0)?.toUpperCase() + row?.tbl_user?.lastName?.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.user_id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Comment Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Comment",
        selector: (row) => row.comment,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row.comment}</span>
            </div>
          </div>
        ),
      },
    ];
  }, [commentData]);
  const likecolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage4 == 1 ? index + 1 : (currentPage4 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Liked By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row?.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0)?.toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0)?.toUpperCase() + row?.tbl_user?.lastName?.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.user_id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Liked Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
    ];
  }, [likeData]);

  const offensivecolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage6 == 1 ? index + 1 : (currentPage6 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Reported By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={
                row?.tbl_user
                  ? findUpper(
                      row?.tbl_user?.firstName?.charAt(0)?.toUpperCase() +
                        row?.tbl_user?.firstName?.slice(1) +
                        " " +
                        row?.tbl_user?.lastName?.charAt(0)?.toUpperCase() +
                        row?.tbl_user?.lastName?.slice(1)
                    )
                  : ""
              }
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Reported Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
       
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },

      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row.status}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Resolved Date",
        selector: (row) => moment(row.resolved_at).format("MM-DD-YYYY"),
       
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row.resolved_at ? moment(row.resolved_at).format("MM-DD-YYYY") : "--"}</span>
            </div>
          </div>
        ),
      },
      {
        name: "View Offense",
        selector: (row) => row.resource_id,
        grow: 2,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showoffensivedetails(row);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [offensiveData]);

  const columns1 = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage11 == 1 ? index + 1 : (currentPage11 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Saved By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0).toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0).toUpperCase() + row?.tbl_user?.lastName.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.tbl_user.id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Saved Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Saved For(Student)",
        selector: (row) => row.tbl_child.firstName + " " + row.tbl_child.lastName,
        sortable: true,
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">
                {" "}
                {row.tbl_child && row.tbl_child.firstName ? row.tbl_child.firstName : ""}{" "}
                {row.tbl_child && row.tbl_child.lastName ? row.tbl_child.lastName : ""}{" "}
              </span>
            </div>
          </div>
        ),
      },

      {
        name: "View",
        selector: (row) => row.resource_id,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showsaveddetails(row);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [saveData]);
  const reviewcolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage10 == 1 ? index + 1 : (currentPage10 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: "Reviewed By",
        selector: (row) => (row.tbl_user ? row.tbl_user.firstName + " " + row.tbl_user.lastName : ""),
        minWidth: "170px",
        style: { minWidth: "170px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row?.tbl_user ? findUpper(row?.tbl_user?.firstName?.charAt(0).toUpperCase() + row?.tbl_user?.firstName?.slice(1) + " " + row?.tbl_user?.lastName?.charAt(0).toUpperCase() + row?.tbl_user?.lastName?.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.tbl_user && row.tbl_user.firstName ? row.tbl_user.firstName : ""}{" "}
                {row.tbl_user && row.tbl_user.lastName ? row.tbl_user.lastName : ""}{" "}
                <Link to={`/view-customer-details/${row.tbl_user.id}`}>
                  {" "}
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-eye"></em>
                  </button>
                </Link>
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Reviewed Date",
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            </div>
          </div>
        ),
      },
      {
        name: "Comment",
        selector: (row) => "",
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="sub-text">{row.message ? row.message : "--"}</span>
            </div>
          </div>
        ),
      },

      {
        name: "Rating",
        selector: (row) => row.rating,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <div className="rating">
                {star.map((temp) => (
                  <>
                    {temp == row.rating ? (
                      <div className="star">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                      </div>
                    ) : temp < row.rating ? (
                      <div className="star">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    ) : (
                      <div className="star unfilled">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    )}
                  </>
                ))}
              </div>
              {/* <span className="sub-text">{row.rating}</span> */}
            </div>
          </div>
        ),
      },
    ];
  }, [reviewData]);
  const contentcolumns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {index + 1} </div>
        </>
      ),
    },

    {
      name: "Content ",
      selector: (row) => (row.file_path ? row.file_path : ""),
      minWidth: "150px",
      style: { minWidth: "150px" },
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.file_path && row.file_path.split("/")[2] ? row.file_path.split("/")[2] : ""}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Content Type",
      selector: (row) => row.file_type,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.file_type}</span>
          </div>
        </div>
      ),
    },

    {
      name: "Action",
      selector: (row) => row.resource_id,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="btn btn-md px-0"
            onClick={() => {
              showContentPreview(row.content, row.file_type);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              if (document.body.classList.contains("savepage")) {
                document.body.classList.add("removescroll");
              } else {
                document.body.classList.remove("removescroll");
              }
            }}
          >
            <span className="ed">preview </span>
          </button>
        </div>
      ),
    },
  ];

  const mentoringcolumns = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row) => row.id,
        grow: 0,
        minWidth: "60px",
        style: { minWidth: "60px" },
        borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              {" "}
              {currentPage5 == 1 ? index + 1 : (currentPage5 - 1) * 10 + (index + 1)}{" "}
            </div>
          </>
        ),
      },
      {
        name: <div dangerouslySetInnerHTML={{ __html: "Booking Date/</br>Session Type" }} />,
        selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
        grow: 2,
        // minWidth: "140px",
        // style: { minWidth: "150px" },
        // borderRadius: "4px",
        cell: (row, index) => (
          <>
            <div className="user-card mt-2 mb-2">
              <div className="user-info">
                <span className="tb-lead"> {moment(row.created_at).format("MM-DD-YYYY")}</span>
                <br />
                <span>{row.schedule_type == "1" ? "Group Session" : "Individual Session"}</span>
              </div>
            </div>
          </>
        ),
      },

      {
        name: "Purchased By ",
        selector: (row) => (row.purchased_user ? row.purchased_user.firstName + " " + row.purchased_user.lastName : ""),
        minWidth: "150px",
        style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              // theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.purchased_user ? findUpper(row.purchased_user.firstName.charAt(0).toUpperCase() + row.purchased_user.firstName.slice(1) + " " + row.purchased_user.lastName.charAt(0).toUpperCase() + row.purchased_user.lastName.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.purchased_user && row.purchased_user.firstName ? row.purchased_user.firstName : ""}{" "}
                {row.purchased_user && row.purchased_user.lastName ? row.purchased_user.lastName : ""}{" "}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Sold By ",
        selector: (row) => (row.sold_by_user ? row.sold_by_user.firstName + " " + row.sold_by_user.lastName : ""),
        // minWidth: "150px",
        // style: { minWidth: "150px" },
        grow: 2,
        sortable: true,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            {/* <UserAvatar
              theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]}
              text={row.sold_by_user ? findUpper(row.sold_by_user.firstName.charAt(0).toUpperCase() + row.sold_by_user.firstName.slice(1) + " " + row.sold_by_user.lastName.charAt(0).toUpperCase() + row.sold_by_user.lastName.slice(1)) : ""}
            ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row.sold_by_user && row.sold_by_user.firstName ? row.sold_by_user.firstName : ""}{" "}
                {row.sold_by_user && row.sold_by_user.lastName ? row.sold_by_user.lastName : ""}{" "}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "View",
        selector: (row) => row.resource_id,
        cell: (row) => (
          <div className="user-card mt-2 mb-2">
            <button
              className="dropdown-item"
              onClick={() => {
                showmentoringdetails(row);
              }}
            >
              <Icon name="eye"></Icon>
            </button>
          </div>
        ),
      },
    ];
  }, [mentoringData]);

  const columnsGroup = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageGroup == 1 ? index + 1 : (currentPageGroup - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Joined Date",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row?.last_updated_at ? moment(row?.last_updated_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },

    {
    
      name: "Group Members",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            <span className="sub-text">{row.tbl_user.phone}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Group Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.tbl_group.group_name ? row?.tbl_group.group_name : "--"}</span>
          </div>
        </div>
      ),
    },
  ];

  const columnsResourceGetInfo = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageResourceGetInfo == 1 ? index + 1 : (currentPageResourceGetInfo - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Requested Date",
      selector: (row) => "",
      grow: 2,
      sortable: true,

      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      minWidth: "180px",
      style: { minWidth: "180px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            {/* <span className="sub-text">{row.tbl_user.phone}</span> */}
          </div>
        </div>
      ),
    },
  ];

  const columnsResourceEnroll = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageResourceEnroll == 1 ? index + 1 : (currentPageResourceEnroll - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Requested Date",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.tbl_user.firstName ? row?.tbl_user.firstName : "--"} {row?.tbl_user.lastName}
            </span>
            <span className="sub-text">{row.tbl_user.email}</span>
            {/* <span className="sub-text">{row.tbl_user.phone}</span> */}
          </div>
        </div>
      ),
    },
  ];

  const columnsAffiliateSignup = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPageAffiliateSignup == 1 ? index + 1 : (currentPageAffiliateSignup - 1) * 10 + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Requested Date",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead"> {row?.created_at ? moment(row?.created_at).format("MM-DD-YYYY") : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      minWidth: "150px",
      style: { minWidth: "150px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row?.purchased_user.firstName ? row?.purchased_user.firstName : "--"} {row?.purchased_user.lastName}
            </span>
            <span className="sub-text">{row.purchased_user.email}</span>
            {/* <span className="sub-text">{row.user.phone}</span> */}
          </div>
        </div>
      ),
    },
    {
      name: "Child Name",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row?.tbl_child.firstName ? row?.tbl_child.firstName : "--"} {row?.tbl_child.lastName}
            </span>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Signup Date/Time ",
    //   selector: (row) => "",
    //   grow: 2,
    //   sortable: true,
    //   minWidth: "150px",
    //   style: { minWidth: "150px" },
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         {row?.tbl_user_mentoring_session_datetimes && row?.tbl_user_mentoring_session_datetimes.map((val, i) => {
    //           return (
    //             <div>
    //               <span className="tb-lead">{val?.user_session_date ? moment(val?.user_session_date).format("MM-DD-YYYY") : "--"} </span>
    //               <span className="sub-text">{val?.user_session_time ? getTime(val?.user_session_time) : "--"}</span>
    //             </div>

    //           )
    //         })}

    //       </div>
    //     </div>

    //   ),
    // },
    {
      name: "View",
      selector: (row) => "",
      grow: 2,
      sortable: true,
      minWidth: "60px",
      style: { minWidth: "60px" },
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button
            className="dropdown-item"
            onClick={() => {
              showSignupdetails(row);
            }}
          >
            <Icon name="eye"></Icon>
          </button>
        </div>
      ),
    },
    // {
    //   name: "Duration/Schedule Type",
    //   selector: (row) => "",
    //   grow:2,
    //   sortable: true,
    //   cell: (row) => (
    //   <div className="user-card mt-2 mb-2">
    //     <div className="user-info">
    //      <span className="sub-text">{row?.duration ? row?.duration+"min" :"0.00 min"}</span>
    //      <span className="sub-text">{row?.schedule_type && row?.schedule_type == "1"?"Group Session":"Individual Session"}</span>
    //     </div>
    //   </div>

    //   ),
    // },

    // {
    //   name: "Status",
    //   selector: (row) => "",
    //   grow:2,
    //   sortable: true,
    //   cell: (row) => (
    //   <div className="user-card mt-2 mb-2">
    //     <div className="user-info">
    //      <span className="sub-text">{row?.status}</span>
    //     </div>
    //   </div>

    //   ),
    // },
  ];

  const [signUpDetails, setSignupDetails] = useState("");
  const [signupDetailModal, setSignupDetailModal] = useState(false);

  const SignupModalFunction = () => {
    setSignupDetailModal(!signupDetailModal);
  };

  const showSignupdetails = (id) => {
    setSignupDetails(id);
    // console.log(id, "id")
    setSignupDetailModal(true);
  };

  const showmentoringdetails = (mid) => {
    setMentoringDetails(mid);
    // console.log(mid, "mid")
    setMentoringOpen(true);
  };
  const showpaymentdetails = (pid) => {
    setPaymentDetails(pid);
    // console.log(pid, "pid")
    setPayOpen(true);
  };

  const showshareddetails = (sid, uid, id) => {
    setLoader(true);
    let data = {
      resource_id: sid,
      user_id: uid,
      id: id,
    };
    // console.log("payload share", data)
    makePostRequest(URLS.GET_SHARE_LIST, null, data, null).then((response) => {
      // console.log("response share ", response)
      if (response.code == 200) {
        setFriendList(response.data.data.username);
        setGroupList(response.data.data.groupname);
        setLoader(false);
        setSharedOpen(true);
      } else setLoader(false);
    });
  };
  const showoffensivedetails = (oid) => {
    // console.log("oid", oid)
    setOffensiveDetails(oid);
    setOffensiveOpen(true);
  };
  const showsaveddetails = (sid) => {
    setSavedDetails(sid);
    // console.log("SId", sid)
    setSaveOpen(true);
  };
  const HandleFromDatePurchase = (e) => {
    setSelectedFromDatePurchase(e);
     //console.log(selectedfromdatepurchase, "fdatecccccccccccc")
    //settoDateError("");
	setformDateErrorPurchase("")
  };
  const HandleToDatePurchase = (e) => {
    setSelectedToDatePurchase(e);
    //setformDateError("");
	//console.log(setSelectedToDatePurchase, "fdatecccccccccccc")
	settoDateErrorPurchase("")
  };
  const HandleFromDateShared = (e) => {
    setSelectedFromDateShared(e);
    // console.log(selectedfromdateshared, "fdate")
    setfromDateErrorShared("");
  };
  const HandleToDateShared = (e) => {
    setSelectedToDateShared(e);
    settoDateErrorShared("");
  };
  const HandleFromDateReview = (e) => {
    setSelectedFromDateReview(e);
    // console.log(selectedfromdatereview, "fdate")
    setfromDateErrorReview("");
  };
  const HandleToDateReview = (e) => {
    setSelectedToDateReview(e);
    settoDateErrorReview("");
  };
  const HandleFromDateComment = (e) => {
    setSelectedFromDateComment(e);
    // console.log(selectedfromdatecomment, "fdate")
    setfromDateErrorComment("");
  };
  const HandleToDateComment = (e) => {
    setSelectedToDateComment(e);
    settoDateErrorComment("");
  };
  const HandleFromDateLike = (e) => {
    setSelectedFromDateLike(e);
    // console.log(selectedfromdatelike, "fdate")
    setfromDateErrorLike("");
  };

  const HandleToDateLike = (e) => {
    setSelectedToDateLike(e);
    settoDateErrorLike("");
  };
  const HandleFromDateOffensive = (e) => {
    setSelectedFromDateOffensive(e);
    // console.log(selectedfromdateoffensive,"fdate")
    setFromDateErrorOffensive("");
  };
  const HandleToDateOffensive = (e) => {
    setSelectedToDateOffensive(e);
    setToDateErrorOffensive("");
  };
  const HandleFromDateSaved = (e) => {
    setSelectedFromDateSaved(e);
    // console.log(selectedfromdatesaved,"fdate")
    setfromDateErrorSaved("");
  };
  const HandleToDateSaved = (e) => {
    setSelectedToDateSaved(e);
    settoDateErrorSaved("");
  };

  const HandleFromDateMentoring = (e) => {
    setSelectedFromDateMentoring(e);
    setFromDateErrorMentoring("");
  };
  const HandleToDateMentoring = (e) => {
    setSelectedToDateMentoring(e);
    setToDateErrorMentoring("");
  };

  const HandleFromDateGroupResource = (e) => {
    setSelectedFromDateGroupResource(e);
    setFromDateErrorGroupResource("");
  };
  const HandleToDateGroupResource = (e) => {
    setSelectedToDateGroupResource(e);
    setToDateErrorGroupResource("");
  };

  const HandleFromDateResourceGetInfo = (e) => {
    setSelectedFromDateResourceGetInfo(e);

    setFromDateErrorResourceGetInfo("");
  };
  const HandleToDateResourceGetInfo = (e) => {
    setSelectedToDateResourceGetInfo(e);
    setToDateErrorResourceGetInfo("");
  };

  const HandleFromDateResourceEnrol = (e) => {
    setSelectedFromDateResourceEnrol(e);
    settoDateError("");
  };
  const HandleToDateResourceEnrol = (e) => {
    setSelectedToDateResourceEnrol(e);
    setformDateError("");
  };

  const HandleFromDateResourceSignup = (e) => {
    setSelectedFromDateResourceSignup(e);
    // settoDateError("")
  };
  const HandleToDateResourceSignup = (e) => {
    setSelectedToDateResourceSignup(e);
    // setformDateError("")
  };
  const handlesavedfilter = () => {
	 if(selectedfromdatesaved != null && selectedtodatesaved == null ) {
        settoDateErrorSaved("please select end date")
	 }
	else if(selectedtodatesaved != null && selectedfromdatesaved == null ) {
		setfromDateErrorSaved("please select start date") 
	}
	else if(selectedfromdatesaved>selectedfromdatesaved) {
		toast.error("end date should be grater than start date")
	}	
	 else {
      getSavedDetails();
	 }
  };

  const handlepurchasefilter = () => {
	  //console.log("ttttttttttttt",selectedfromdatepurchase )
	if(selectedfromdatepurchase != null && selectedtodatepurchase== null){
		 settoDateErrorPurchase("please select end date")
		  
		}
		else if(selectedtodatepurchase != null && selectedfromdatepurchase== null){
		  setformDateErrorPurchase("please select start date") 
		}
		else if(selectedfromdatepurchase>selectedtodatepurchase){
	     toast.error("end date should be grater than start date")
		}
		else{  
          getpurchasedetails();
		}
  };

  const handlereviewfilter = () => {
	  if(selectedfromdatereview != null && selectedtodatereview == null){
		settoDateErrorReview("please select end date") 
	  }
	  else if (selectedtodatereview != null && selectedfromdatereview == null ) {
		setfromDateErrorReview("please select start date")   
	  }
	  else if (selectedfromdatereview>selectedtodatereview) {
		 toast.error("end date should be grater than start date") 
	  }
	  else {
       getreviewdetails();
	  }
  };
  const handlesharedfilter = () => {
	  if(selectedfromdateshared != null && selectedtodateshared == null ){
		 settoDateErrorShared("please select end date")  
	  }
	  else if (selectedtodateshared != null && selectedfromdateshared == null ) {
		setfromDateErrorShared("please select start date")     
	  }
	  else if (selectedfromdateshared>selectedtodateshared) {
		 toast.error("end date should be grater than start date") 
	  }
       else {
	       getSharedDetails();
	   }
  };
  const handlecommentfilter = () => {
	if(selectedfromdatecomment != null && selectedtodatecomment == null){
       settoDateErrorComment("please select end date") 
	}
    else if(selectedtodatecomment != null && selectedfromdatecomment == null ){
      setfromDateErrorComment("please select start date") 
	}
    else if(selectedfromdatecomment>selectedtodatecomment){
      toast.error("end date should be grater than start date")
	}
    else {	
    getCommentsdetails();
    }
  };
  const handlelikefilter = () => {
	  if(selectedfromdatelike != null && selectedtodatelike == null){
		 settoDateErrorLike("please select end date")
	  }
	  else if(selectedtodatelike != null && selectedfromdatelike == null ){
		  setfromDateErrorLike("please select start date")
	  }
	  else if(selectedfromdatelike>selectedtodatelike){
		  toast.error("end date should be grater than start date")
	  }
	  else {
       getLikedetails();
	  }
  };
  const handleoffensivefilter = () => {
	  if(selectedfromdateoffensive != null && selectedtodateoffensive == null){
		 setToDateErrorOffensive("please select end date")
	  }
	  else if(selectedtodateoffensive != null && selectedfromdateoffensive == null ){
		  setFromDateErrorOffensive("please select start date")
	  }
	  else if(selectedfromdateoffensive>selectedtodateoffensive){
		  toast.error("end date should be grater than start date")
	  }
	  else {
        getOffensivedetails();
	  }
  };
  const handlementoringfilter = () => {
	  if(selectedfromdatementoring != null && selectedtodatementoring == null) {
		  setToDateErrorMentoring("please select end date")
	  }
	   else if(selectedtodatementoring != null && selectedfromdatementoring == null ) {
		   setFromDateErrorMentoring("please select start date")
	  }
	  else if(selectedfromdatementoring>selectedtodatementoring) {
		 toast.error("end date should be grater than start date")  
	  }
	  else {
       getMentoringdetails();
	  }
  };
  const handleGroupResourcefilter = () => {
	  if(selectedfromdateGroupResource != null && selectedtodateGroupResource == null ){
		  setToDateErrorGroupResource("please select end date")
	  }
	  if(selectedtodateGroupResource != null && selectedfromdateGroupResource == null ){
		  setFromDateErrorGroupResource("please select start date")
	  }
	  if(selectedfromdateGroupResource>selectedtodateGroupResource){
		 toast.error("end date should be grater than start date")  
	  }
	  else {
        GetGroupList();
	  }
  };
   
  const handleResourceGetInfoSearch = () => {
	  if(selectedfromdateResourceGetInfo != null && selectedtodateResourceGetInfo == null ){
		  setToDateErrorResourceGetInfo("please select end date")
	  }
	  if(selectedtodateResourceGetInfo != null && selectedfromdateResourceGetInfo == null ){
		  setFromDateErrorResourceGetInfo("please select start date")
	  }
	  if(selectedfromdateResourceGetInfo>selectedtodateResourceGetInfo){
		 toast.error("end date should be grater than start date")  
	  }
	  else {
         GetResourceGetInfoList();
	  }
  };

  const handleResourceEnrolSearch = () => {
    GetResourceEnrollList();
  };

  const handleResourceSignupSearch = () => {
    GetAffiliateSignupList();
  };

  const handleselectchild = (e) => {
    setSelectChild(e.target.value);
  };
  const handleSharedWith = (e) => {
    setSharedWith(e.target.value);
  };

  const handleEye = (userid) => {
    // console.log(userid, "userid")
  };
  const getResourceContent = () => {
    let data = {
      resource_id: id,
    };
    makePostRequest(URLS.contentRead, null, data, null).then((res) => {
      let resourceData = res.data;
      // console.log(resourceData, "imagedata");
      setResourceContent(resourceData);
      let arr = [];
      resourceData &&
        resourceData.length > 0 &&
        resourceData.forEach((val, i) => {
          if (val.status == "not_deleted") {
            let imgName = val.file_path;
            imgName = imgName.split("/");
            if (val.content_thumbnail === "yes") {
              // setThumbnailImgName(imgName);
            } else {
              arr.push({
                id: val.id,
                name: imgName[2],
                imgurl: val.content,
                imgType: val.file_type,
              });
            }
          }
        });
      setUploadContentName(arr);
    });
  };

  useEffect(() => {
    getResourceContent();
  }, [resource_id]);

  const [imageContent, setImageContent] = useState("");

  const handleSelectFor = (e) => {
    setSelectedFor(e.target.value);
  };
  const handleGradeLevel = (e) => {
    setSelectedGradeLevel(e.target.value);
  };
  const handlviewresource = () => {
    setLoader(true);
    let data = {
      id: resource_id,
    };
    // console.log("getone payload",data)
    makePostRequest(URLS.GetOne, null, data, null)
      .then((res) => {
        setLoader(true);
        console.log(res, "view resource");
        if (res.code === 200) {
          setResourceData(res?.data);
          setchilddata(res?.data?.tbl_child_resource_mappings);
          setImageContent(res?.data?.tbl_resource_uploaded_files);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [currentPage1, setCurrentPage1] = useState(1);
  const [count1, setCount1] = useState();
  const [purchaseCount, setPurchaseCount] = useState();
  const getpurchasedetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage1,
      start_date: selectedfromdatepurchase ? moment(selectedfromdatepurchase).format("YYYY-MM-DD") : "",
      end_date: selectedtodatepurchase ? moment(selectedtodatepurchase).format("YYYY-MM-DD") : "",
      limit: 10,
    };
    //console.log(data," purchase data")
    makePostRequest(URLS.GET_PURCHASE_DETAILS_RES, null, data, null)
      .then((res) => {
        setLoader(true);
        if (res.code === 200) {
          console.log(res, "purchase data");
          setPurchaseData(res.data.data);
          setPurchaseCount(res.data.count);
          setCount1(Math.ceil(res.data.count / 10));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange1 = (selectedPage) => {
    setCurrentPage1(selectedPage.selected + 1);
  };

  useEffect(() => {
    getpurchasedetails();
  }, [currentPage1]);

  const [currentPage10, setCurrentPage10] = useState(1);
  const [count10, setCount10] = useState();
  const [reviewCount, setReviewCount] = useState();

  const getreviewdetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage10,
      start_date: selectedfromdatereview ? moment(selectedfromdatereview).format("YYYY-MM-DD") : "",
      end_date: selectedtodatereview ? moment(selectedtodatereview).format("YYYY-MM-DD") : "",
      limit: 10,
    };

    makePostRequest(URLS.GET_REVIEW_DETAILS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          console.log(res, "review data");
          setLoader(false);
          setReviewData(res.data.data);
          setReviewCount(res.data.count);
          setCount10(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange10 = (selectedPage) => {
    setCurrentPage10(selectedPage.selected + 1);
  };
  // const getreviewfetch = () => {
  //   // setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage10,
  //     "start_date": selectedfromdatereview ? moment(selectedfromdatereview).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodatereview ? moment(selectedtodatereview).add(1, 'd').format("YYYY-MM-DD") : "",

  //   };

  //   makePostRequest(URLS.GET_REVIEW_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {

  //         setLoader(false);
  //         setReviewData(res.data.data);
  //         setReviewCount(res.data.count)
  //         setCount10(Math.ceil(res.data.count / 10));
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    getreviewdetails();
  }, [currentPage10]);

  const [currentPage2, setCurrentPage2] = useState(1);
  const [count2, setCount2] = useState();

  const handlePageChange2 = (selectedPage) => {
    setCurrentPage2(selectedPage.selected + 1);
  };
  const getSharedDetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage2,
      start_date: selectedfromdateshared ? moment(selectedfromdateshared).format("YYYY-MM-DD") : "",
      end_date: selectedtodateshared ? moment(selectedtodateshared).format("YYYY-MM-DD") : "",
      shared_by: sharedwith,
      limit: 10,
    };

    makePostRequest(URLS.GET_SHARED_DETAILS_RES, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          console.log(res, "Shared details");
          setLoader(false);
          setSharedData(res.data.data);
          setCount2(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getsharefetch = () => {
  //   // setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage2,
  //     "start_date": selectedfromdateshared ? moment(selectedfromdateshared).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodateshared ? moment(selectedtodateshared).add(1, 'd').format("YYYY-MM-DD") : "",

  //   };
  //   //  console.log(data,"data")
  //   makePostRequest(URLS.GET_SHARED_DETAILS_RES, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         // console.log(res, "Shared details");
  //         setLoader(false);
  //         setShareCount(res.data.count);
  //         setSharedData(res.data.data);
  //         setCount2(Math.ceil(res.data.count / 10));
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    getSharedDetails();
  }, [currentPage2]);

  const [currentPage11, setCurrentPage11] = useState(1);
  const [count11, setCount11] = useState();
  const [saveCount, setSaveCount] = useState();

  const getSavedDetails = () => {
    let data = {
      resource_id: resource_id,
      page: currentPage11,
      start_date: selectedfromdatesaved ? moment(selectedfromdatesaved).format("YYYY-MM-DD") : "",
      end_date: selectedtodatesaved ? moment(selectedtodatesaved).format("YYYY-MM-DD") : "",
      child_id: selectchild,
      grade_id: selectedGradeLevel,
      resource_for: selectedFor,
      limit: 10,
    };
    //  console.log(data,"data")
    makePostRequest(URLS.GET_SAVED_DETAILS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "saved  data");
          setLoader(false);
          setSaveData(res.data.data);
          setSaveCount(res.data.count);
          setCount11(Math.ceil(res.data.count / 10));
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange11 = (selectedPage) => {
    setCurrentPage11(selectedPage.selected + 1);
  };
  // const getsavefetch = () => {

  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage11,
  //     "start_date": selectedfromdatesaved ? moment(selectedfromdatesaved).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodatesaved ? moment(selectedtodatesaved).add(1, 'd').format("YYYY-MM-DD") : "",
  //     "child_id": selectchild,
  //     "grade_id": selectedGradeLevel,
  //     "resource_for": selectedFor,
  //   };

  //   makePostRequest(URLS.GET_SAVED_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         // console.log(res, "saved details");
  //         setLoader(false);
  //         setSaveData(res.data.data);
  //         setsavechilddata(res.data.data);
  //         setSaveCount(res.data.count)
  //         setCount11(Math.ceil(res.data.count / 10));
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    getSavedDetails();
  }, [currentPage11]);

  const [currentPage3, setCurrentPage3] = useState(1);
  const [count3, setCount3] = useState();
  const [commentCount, setCommentCount] = useState();
  const handlePageChange3 = (selectedPage) => {
    setCurrentPage3(selectedPage.selected + 1);
  };
  const getCommentsdetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage3,
      start_date: selectedfromdatecomment ? moment(selectedfromdatecomment).format("YYYY-MM-DD") : "",
      end_date: selectedtodatecomment ? moment(selectedtodatecomment).format("YYYY-MM-DD") : "",
      limit: 10,
    };

    makePostRequest(URLS.GET_COMMENTS_DETAILS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Comments details");
          setLoader(false);
          setCommentData(res.data.data);
          setCount3(Math.ceil(res.data.count / 10));
          setCommentCount(res.data.count);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getCommentsfetch = () => {
  //   // setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage3,
  //     "start_date": selectedfromdatecomment ? moment(selectedfromdatecomment).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodatecomment ? moment(selectedtodatecomment).add(1, 'd').format("YYYY-MM-DD") : "",

  //   };
  //   //console.log(data,"data")
  //   makePostRequest(URLS.GET_COMMENTS_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         //console.log(res, "Comments details");
  //         setLoader(false);
  //         setCommentData(res.data.data);
  //         setCount3(Math.ceil(res.data.count / 10));
  //         setCommentCount(res.data.count);
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  useEffect(() => {
    getCommentsdetails();
  }, [currentPage3]);

  const [currentPage4, setCurrentPage4] = useState(1);
  const [count4, setCount4] = useState();
  const [likeCount, setLikeCount] = useState();
  const handlePageChange4 = (selectedPage) => {
    setCurrentPage4(selectedPage.selected + 1);
  };
  const getLikedetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage4,
      start_date: selectedfromdatelike ? moment(selectedfromdatelike).format("YYYY-MM-DD") : "",
      end_date: selectedtodatelike ? moment(selectedtodatelike).format("YYYY-MM-DD") : "",
      limit: 10,
    };

    makePostRequest(URLS.GET_LIKE_DETAILS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          //  console.log(res, "LIke details");
          setLoader(false);
          setLikeData(res.data.data);
          setCount4(Math.ceil(res.data.count / 10));
          setLikeCount(res.data.count);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getLikedfetch = () => {
  //   // setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage4,
  //     "start_date": selectedfromdatelike ? moment(selectedfromdatelike).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodatelike ? moment(selectedtodatelike).add(1, 'd').format("YYYY-MM-DD") : "",

  //   };

  //   makePostRequest(URLS.GET_LIKE_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         // console.log(res, "LIke details");
  //         setLoader(false);
  //         setLikeData(res.data.data);
  //         setCount4(Math.ceil(res.data.count / 10));
  //         setLikeCount(res.data.count);
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    getLikedetails();
  }, [currentPage4]);

  const [currentPage5, setCurrentPage5] = useState(1);
  const [count5, setCount5] = useState();
  const [mentoringCount, setMentoringCount] = useState();

  const handlePageChange5 = (selectedPage) => {
    setCurrentPage5(selectedPage.selected + 1);
  };

  // const getMentoringfetch = () => {
  //   setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage5,
  //     "start_date": selectedfromdatementoring ? moment(selectedfromdatementoring).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodatementoring ? moment(selectedtodatementoring).add(1, 'd').format("YYYY-MM-DD") : "",

  //   };

  //   makePostRequest(URLS.GET_MENTORING_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         // console.log(res, "MENTORING details");
  //         setLoader(false);
  //         setMentoringData(res.data.data);
  //         setCount5(Math.ceil(res.data.count / 10));
  //         setMentoringCount(res.data.count);
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const getMentoringdetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage5,
      start_date: selectedfromdatementoring ? moment(selectedfromdatementoring).format("YYYY-MM-DD") : "",
      end_date: selectedtodatementoring ? moment(selectedtodatementoring).format("YYYY-MM-DD") : "",
    };

    makePostRequest(URLS.GET_MENTORING_DETAILS_RESOURCE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          console.log(res, "MENTORING details");
          setLoader(false);
          setMentoringData(res.data.data);
          setCount5(Math.ceil(res.data.count / 10));
          setMentoringCount(res.data.count);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMentoringdetails();
  }, [currentPage5]);

  const [currentPage6, setCurrentPage6] = useState(1);
  const [count6, setCount6] = useState();
  const [offensiveCount, setOffensiveCount] = useState();
  const handlePageChange6 = (selectedPage) => {
    setCurrentPage6(selectedPage.selected + 1);
  };

  const getOffensivedetails = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page: currentPage6,
      start_date: selectedfromdateoffensive ? moment(selectedfromdateoffensive).format("YYYY-MM-DD") : "",
      end_date: selectedtodateoffensive ? moment(selectedtodateoffensive).format("YYYY-MM-DD") : "",
    };

    makePostRequest(URLS.GET_OFFENSIVE_DETAILS, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "Offensive details");
          setLoader(false);
          setOffensiveData(res.data.data);
          setCount6(Math.ceil(res.data.count / 10));
          setOffensiveCount(res.data.count);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getOffensivefetch = () => {
  //   setLoader(true);
  //   let data = {
  //     resource_id: resource_id,
  //     page: currentPage6,
  //     start_date: selectedfromdateoffensive ? moment(selectedfromdateoffensive).format("YYYY-MM-DD") : "",
  //     end_date: selectedtodateoffensive ? moment(selectedtodateoffensive).format("YYYY-MM-DD") : "",
  //   };

  //   makePostRequest(URLS.GET_OFFENSIVE_DETAILS, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         //console.log(res, "Offensive details");
  //         setLoader(false);
  //         setOffensiveData(res.data.data);
  //         setCount6(Math.ceil(res.data.count / 10));
  //         setOffensiveCount(res.data.count);
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  /*=============================GRoup Api calling here===========================*/

  const [currentPageGroup, setCurrentPageGroup] = useState(1);
  const [countGroup, setCountGroup] = useState();

  const GetGroupList = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page_number: currentPageGroup,
      start_date: selectedfromdateGroupResource ? moment(selectedfromdateGroupResource).format("YYYY-MM-DD") : "",
      end_date: selectedtodateGroupResource ? moment(selectedtodateGroupResource).format("YYYY-MM-DD") : "",
      limit: 10,
    };

    makePostRequest(URLS.GETGROUPLIST_RESOURCE, null, data, null)
      .then((res) => {
        setLoader(false);
         console.log(res,"Group list")
        if (res.code == 200) {
          setGroupListResource(res.data.groupResource);
          setCountGroup(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeGroup = (selectedPage) => {
    setCurrentPageGroup(selectedPage.selected + 1);
  };

  const fetchDataGroup = () => {
    let data = {
      resource_id: resource_id,
      page_number: currentPageGroup,
      limit: 10,
    };
    makePostRequest(URLS.GETGROUPLIST_RESOURCE, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setGroupListResource(res.data.groupResource);
          setCountGroup(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataGroup();
  }, [currentPageGroup]);

  /*=============================Add More Info Request Api calling here===========================*/

  const [resourceGetInfo, setResourceGetInfo] = useState([]);
  const [currentPageResourceGetInfo, setCurrentPageResourceGetInfo] = useState(1);
  const [countResourceGetInfo, setCountResourceGetInfo] = useState();
  const [totalCountInfo, setTotalCountInfo] = useState("");

  const GetResourceGetInfoList = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      // resource_id:12104,
      page_number: currentPageResourceGetInfo,
      item_per_page: 10,
      start_date: selectedfromdateResourceGetInfo ? moment(selectedfromdateResourceGetInfo).format("YYYY-MM-DD") : "",
      end_date: selectedtodateResourceGetInfo
        ? moment(selectedtodateResourceGetInfo).format("YYYY-MM-DD")
        : "",
    };

    makePostRequest(URLS.GETALLRESOURCEINFO, null, data, null)
      .then((res) => {
        setLoader(false);
        // console.log(res, "ResourceGetInfo")
        if (res.code == 200) {
          setResourceGetInfo(res.data.data);
          setCountResourceGetInfo(Math.ceil(res.data.count / 10));
          setTotalCountInfo(res.data.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeResourceGetInfo = (selectedPage) => {
    setCurrentPageResourceGetInfo(selectedPage.selected + 1);
  };

  const fetchDataResourceGetInfo = () => {
    let data = {
      resource_id: resource_id,
      page_number: currentPageResourceGetInfo,
      item_per_page: 10,
      start_date: selectedfromdateResourceGetInfo ? moment(selectedfromdateResourceGetInfo).format("YYYY-MM-DD") : "",
      end_date: selectedtodateResourceGetInfo
        ? moment(selectedtodateResourceGetInfo).format("YYYY-MM-DD")
        : "",
    };
    makePostRequest(URLS.GETALLRESOURCEINFO, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setResourceGetInfo(res.data.data);
          setCountResourceGetInfo(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataResourceGetInfo();
  }, [currentPageResourceGetInfo]);

  /*============================= Enrollment  Request Api calling here===========================*/

  const [resourceEnroll, setResourceEnroll] = useState([]);
  const [currentPageResourceEnroll, setCurrentPageResourceEnroll] = useState(1);
  const [countResourceEnroll, setCountResourceEnroll] = useState();
  const [totalCountEnroll, setTotalCountEnroll] = useState("");

  const GetResourceEnrollList = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      item_per_page: 10,
      page_number: currentPageResourceEnroll,
      // "resource_id":12085,
      start_date: selectedfromdateResourceEnrol ? moment(selectedfromdateResourceEnrol).format("YYYY-MM-DD") : "",
      end_date: selectedtodateResourceEnrol ? moment(selectedtodateResourceEnrol).format("YYYY-MM-DD") : "",
    };

    makePostRequest(URLS.GETALLRESOURCEENROLL, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "ResourceEnroll");
        if (res.code == 200) {
          setResourceEnroll(res.data.data);
          setCountResourceEnroll(Math.ceil(res.data.count / 10));
          setTotalCountEnroll(res?.data?.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeResourceEnroll = (selectedPage) => {
    setCurrentPageResourceEnroll(selectedPage.selected + 1);
  };

  const fetchDataResourceEnroll = () => {
    let data = {
      resource_id: resource_id,
      item_per_page: 10,
      page_number: currentPageResourceEnroll,
      // "resource_id":12085,
      start_date: selectedfromdateResourceEnrol ? moment(selectedfromdateResourceEnrol).format("YYYY-MM-DD") : "",
      end_date: selectedtodateResourceEnrol ? moment(selectedtodateResourceEnrol).format("YYYY-MM-DD") : "",
    };
    makePostRequest(URLS.GETALLRESOURCEENROLL, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setResourceEnroll(res.data.data);
          setCountResourceEnroll(Math.ceil(res.data.count / 10));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDataResourceEnroll();
  }, [currentPageResourceEnroll]);

  /*=============================Signup  request Api calling here===========================*/

  const [affiliatedSignup, setAffiliatedSignup] = useState([]);
  const [currentPageAffiliateSignup, setCurrentPageAffiliateSignup] = useState(1);
  const [countAffiliateSignup, setCountAffiliateSignup] = useState();
  const [totalCountSignup, setTotalCountSignup] = useState("");

  const GetAffiliateSignupList = () => {
    setLoader(true);
    let data = {
      resource_id: resource_id,
      page_number: currentPageAffiliateSignup,
      item_per_page: 10,
      start_date: selectedfromdateResourceSignup ? moment(selectedfromdateResourceSignup).format("YYYY-MM-DD") : "",
      end_date: selectedtodateResourceSignup
        ? moment(selectedtodateResourceSignup).format("YYYY-MM-DD")
        : "",
    };
    console.log(data, "data signup");

    makePostRequest(URLS.GETAFFILIATEDSIGNUP, null, data, null)
      .then((res) => {
        setLoader(false);
        console.log(res, "Affiliated");
        if (res.code == 200) {
          setAffiliatedSignup(res.data.data);
          setCountAffiliateSignup(Math.ceil(res.data.count / 10));
          setTotalCountSignup(res.data.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChangeAffiliateSignup = (selectedPage) => {
    setCurrentPageAffiliateSignup(selectedPage.selected + 1);
  };

  // const fetchDataAffiliateSignup = () => {
  //   let data = {

  //     resource_id: resource_id,
  //     page_number: currentPageAffiliateSignup,
  //     limit: 10,
  //   }
  //   makePostRequest(URLS.GETAFFILIATEDSIGNUP, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {

  //         setAffiliatedSignup(res.data.data);
  //         setCountAffiliateSignup(Math.ceil(res.data.count / 10));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    GetAffiliateSignupList();
  }, [currentPageAffiliateSignup]);

  useEffect(() => {
    getOffensivedetails();
  }, [currentPage6]);
  const getGradeLevelData = () => {
    makeGetRequest(URLS.GET_ALL_GRADE_LEVEL, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res, "grade level data");
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showContentPreview = (imgurl, imgType) => {
    // console.log(imgname.split(".")[1], "img type");

    // console.log(imgurl, "imgurl");
    // console.log(imgType, "imgType")

    setContentPreview({
      ...contentPreview,
      imgurl: imgurl,
      imgtype: imgType,
    });
    setVisible(true);
  };

  useEffect(() => {
    handlviewresource();
    getpurchasedetails();
    getResourceContent();
    getCommentsdetails();
    getSharedDetails();
    getLikedetails();
    getOffensivedetails();
    getMentoringdetails();
    getreviewdetails();
    getSavedDetails();
    getGradeLevelData();
    setTabno();
    setLoader(true);
    GetGroupList();
    GetResourceGetInfoList();
    GetResourceEnrollList();
    GetAffiliateSignupList();
  }, []);

  const childReviewList = (reviews) => {
    // console.log(reviews, "childreviews");
    setChildReviews(reviews);
    setIsOpen(true);
  };

  useEffect(() => {
    if (passKey === "group") {
      console.log("tertttt", passKey);
      document.body.classList.add("view-resource-passkey");
      return () => {
        document.body.classList.remove("view-resource-passkey");
      };
    }
  }, [passKey]);

  return (
    <>
      <Head title="Resource Details"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4">
          {" "}
          Resource Details ({resourceData?.resource_title})
          {passKey === "group" ? (
            document.body.classList.add("view-resource-passkey")
          ) : (
            <>
              <button
                onClick={() => history.goBack()}
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end"
              >
                <em className="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </button>
            </>
          )}
        </h3>
        <div className="clearNone"></div>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <PreviewCard>
            <Row className="g-gs">
              <Col md={4}>
                <ul className="nav link-list-menu border border-light round m-0">
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "1",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("1");
                        // sessionStorage.setItem("tab", "1");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="user"></Icon>
                      <span>Resource Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "2",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("2");
                        // sessionStorage.setItem("tab", "2");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="lock-alt"></Icon>
                      <span>Purchase Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "3",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("3");
                        // sessionStorage.setItem("tab", "3");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="bell"></Icon>
                      <span>Review Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "4",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("4");
                        // sessionStorage.setItem("tab", "4");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="share"></Icon>
                      <span>Share Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "5",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("5");
                        // sessionStorage.setItem("tab", "5");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="save"></Icon>
                      <span>Save Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "6",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("6");
                        // sessionStorage.setItem("tab", "6");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="comments"></Icon>
                      <span>Comment Details</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "7",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("7");
                        // sessionStorage.setItem("tab", "7");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="thumbs-up"></Icon>
                      <span>Like Details</span>
                    </a>
                  </li>
                  <li></li>
                  {/* <li>
                  <a
                    href="#tab"
                    className={classnames({
                      active: verticalIconTab === "8",
                    })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setVerticalIconTab("8");
                      window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                    }}
                  >
                    <Icon name="link"></Icon>
                    <span>Content Details</span>
                  </a>
                </li> */}
                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "9",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("9");
                        // sessionStorage.setItem("tab", "9");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Scheduled Sessions Details</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "10",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("10");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span> Group list where resource is shared </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "11",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("11");
                        // sessionStorage.setItem("tab", "11");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Offense Details</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "12",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("12");
                        // sessionStorage.setItem("tab", "12");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Get More Info Request</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "13",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("13");
                        // sessionStorage.setItem("tab", "13");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Enroll Request</span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="#tab"
                      className={classnames({
                        active: verticalIconTab === "14",
                      })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setVerticalIconTab("14");
                        // sessionStorage.setItem("tab", "14");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <Icon name="link"></Icon>
                      <span>Signup Request</span>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={8}>
                <TabContent activeTab={verticalIconTab}>
                  <TabPane tabId="1">
                    <Card className="card-bordered card">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Title</div>
                            <div className="data-value text-soft">
                              {resourceData?.resource_title ? resourceData?.resource_title : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Type</div>
                            <div className="data-value">
                              {" "}
                              {resourceData?.tbl_resource_type_master && resourceData?.tbl_resource_type_master?.name
                                ? resourceData?.tbl_resource_type_master?.name
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Subtype</div>
                            <div className="data-value">
                              {" "}
                              {resourceData?.tbl_resource_type_master && resourceData?.resource_type == "6"
                                ? resourceData?.tbl_resource_fundings[0]?.tbl_type_funding_master?.name
                                : resourceData?.resource_type == "3"
                                ? resourceData?.tbl_resource_experiences[0]?.tbl_type_experience_master?.name
                                : resourceData?.resource_type == "1"
                                ? resourceData?.tbl_resource_organisations[0]?.tbl_type_organisation_master?.name
                                : resourceData?.resource_type == "5"
                                ? resourceData?.tbl_resource_supports?.map((val, i) => {
                                    return (
                                      <div className="data-value">
                                        {val.tbl_type_support_master.name}
                                        {i < resourceData?.tbl_resource_supports.length - 1 ? "," : ""}
                                      </div>
                                    );
                                  })
                                : resourceData?.resource_type == "4"
                                ? resourceData?.tbl_resource_supports?.map((val, i) => {
                                    return (
                                      <div className="data-value">
                                        {val.tbl_type_support_master.name}
                                        {i < resourceData?.tbl_resource_supports.length - 1 ? "," : ""}
                                      </div>
                                    );
                                  })
                                : resourceData?.resource_type == "2"
                                ? resourceData?.tbl_resource_contents[0]?.tbl_type_content_master?.name
                                : resourceData?.resource_type == "7"
                                ? resourceData?.tbl_resource_facilities[0]?.tbl_type_facility_master?.name
                                : ""}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource User</div>
                            <div className="data-value">
                              {" "}
                              {resourceData?.tbl_user
                                ? resourceData?.tbl_user.firstName + " " + resourceData?.tbl_user.lastName
                                : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Mode</div>
                            <div className="data-value">
                              {" "}
                              {resourceData?.resource_mode
                                ? resourceData?.resource_mode?.charAt(0).toUpperCase() +
                                  resourceData?.resource_mode?.slice(1)
                                : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Learning Pillar(s)</div>

                            {resourceData?.tbl_pillar_resource_mappings?.length > 0
                              ? resourceData?.tbl_pillar_resource_mappings?.map((val, i) => {
                                  return (
                                    <div className="data-value">
                                      {val?.tbl_learning_pillar_master?.name}
                                      {i < resourceData?.tbl_pillar_resource_mappings?.length - 1 ? "," : ""}
                                    </div>
                                  );
                                })
                              : "--"}
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Grade</div>
                            <div style={{ width: "100%" }}>
                              {resourceData?.tbl_grade_resource_mappings?.length > 0
                                ? resourceData?.tbl_grade_resource_mappings?.map((val, i) => {
                                    return (
                                      <div className="data-value float-start">
                                        {val?.tbl_grade_level?.name ? val?.tbl_grade_level?.name : "--"}
                                        {i < resourceData?.tbl_grade_resource_mappings?.length - 1 ? "," : ""}
                                      </div>
                                    );
                                  })
                                : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Keywords</div>
                            <div style={{ width: "100%" }}>
                              {resourceData?.tbl_resource_keywords?.length > 0
                                ? resourceData?.tbl_resource_keywords?.map((val, i) => {
                                    return (
                                      <div className="data-value float-start">
                                        {val?.keyword ? val?.keyword : "--"}
                                        {i < resourceData?.tbl_resource_keywords?.length - 1 ? "," : ""}
                                      </div>
                                    );
                                  })
                                : "--"}
                            </div>
                          </div>
                        </li>

                        {resourceData.resource_type == 2 && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Time Frame</div>
                              {resourceData?.tbl_resource_timeframes?.length > 0 ? (
                                resourceData?.tbl_resource_timeframes.map((val, i) => {
                                  return (
                                    <div className="data-value">
                                      {val.timeframe}
                                      {i < resourceData?.tbl_resource_timeframes.length - 1 ? "," : ""}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="data-value">--</div>
                              )}
                            </div>
                          </li>
                        )}
                        {resourceData.resource_type == 2 && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Start Date /Time</div>
                              <div className="data-value">
                                {resourceData?.start_date
                                  ? moment(resourceData?.start_date).format("MM-DD-YYYY")
                                  : "--"}
                                {"/"}
                                {resourceData?.start_time ? getTime(resourceData?.start_time) : "--"}
                              </div>
                            </div>
                          </li>
                        )}
                        {resourceData.resource_type == 2 && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">End Date /Time</div>
                              <div className="data-value">
                                {" "}
                                {resourceData?.end_date ? moment(resourceData?.end_date).format("MM-DD-YYYY") : "--"}
                                {"/"}
                                {resourceData?.end_time ? getTime(resourceData?.end_time) : "--"}
                              </div>
                            </div>
                          </li>
                        )}
                        {resourceData.resource_type == 2 && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Instructional Style</div>
                              <div className="data-value text-break">
                                {resourceData?.tbl_resource_instruction_styles?.length > 0
                                  ? resourceData?.tbl_resource_instruction_styles.map((val, i) => {
                                      return (
                                        <div className="data-value">
                                          {val.tbl_instructional_style.name}
                                          {i < resourceData?.tbl_resource_instruction_styles.length - 1 ? "," : ""}
                                        </div>
                                      );
                                    })
                                  : "--"}
                              </div>
                            </div>
                          </li>
                        )}

                        <li className="data-item scrollonviewres">
                          <div className="data-col">
                            <div className="data-label">Description</div>
                            <div
                              className="data-value"
                              dangerouslySetInnerHTML={{
                                __html: resourceData?.description ? resourceData?.description : "--",
                              }}
                            ></div>
                          </div>
                        </li>

                        <li className="data-item scrollonviewres">
                          <div className="data-col">
                            <div className="data-label">About Resource</div>
                            <div
                              className="data-value"
                              dangerouslySetInnerHTML={{
                                __html: resourceData?.about_resource ? resourceData?.about_resource : "--",
                              }}
                            />
                          </div>
                        </li>
                        {resourceData?.resource_mode != "save" ? (
                          <div>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">About Instructor</div>
                                <div
                                  className="data-value"
                                  dangerouslySetInnerHTML={{
                                    __html: resourceData?.about_instructor ? resourceData?.about_instructor : "--",
                                  }}
                                ></div>
                              </div>
                            </li>

                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Resource Requirement</div>
                                <div
                                  className="data-value"
                                  dangerouslySetInnerHTML={{
                                    __html: resourceData?.resource_requirement
                                      ? resourceData?.resource_requirement
                                      : "--",
                                  }}
                                ></div>
                              </div>
                            </li>
                          </div>
                        ) : null}

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Eligible for Public Funding</div>
                            <div className="data-value data-valueCaptilise">
                              {resourceData?.public_funding ? resourceData?.public_funding : "--"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Get More Info</div>
                            <div className="data-value">
                              {resourceData?.affiliate_add_info_button == 1 ? "Yes" : "No"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Enroll</div>
                            <div className="data-value">
                              {resourceData?.affiliate_enroll_button == 1 ? "Yes" : "No"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Purchase</div>
                            <div className="data-value">
                              {resourceData?.affiliate_purchase_button == 1 ? "Yes" : "No"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Resource Signup</div>
                            <div className="data-value">
                              {resourceData?.affiliate_signup_button == 1 ? "Yes" : "No"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Funding State</div>

                            {resourceData?.tbl_resource_funding_states?.length > 0
                              ? resourceData?.tbl_resource_funding_states?.map((val, i) => {
                                  return <div className="data-value">{val?.tbl_state.name} </div>;
                                })
                              : "--"}
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Purchase Price</div>
                            <div className="data-value">
                              {resourceData?.purchase_price
                                ? new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(resourceData?.purchase_price)
                                : "$0.00"}
                            </div>
                          </div>
                        </li>
                        {resourceData.resource_type == 2 && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">ISBN Number</div>
                              <div className="data-value">{resourceData?.isbn ? resourceData?.isbn : "--"}</div>
                            </div>
                          </li>
                        )}
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Location</div>
                            <div className="data-value">{resourceData?.location ? resourceData?.location : "--"}</div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Rating</div>
                            <div className="data-value">
                              {resourceData?.rating ? (resourceData?.rating).toFixed(1) : "--"}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Space Used by Resource</div>
                            <div className="data-value">
                              {resourceData?.space_used_by_resource
                                ? resourceData?.space_used_by_resource + "mb"
                                : "--"}{" "}
                            </div>
                          </div>
                        </li>

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Status</div>
                            <div className="data-value"> {resourceData?.status ? resourceData?.status : "--"}</div>
                          </div>
                        </li>
                        {resourceData?.resource_mode == "save" && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Storing Type</div>
                              <div className="data-value">
                                {" "}
                                {resourceData?.storing_type === "Learning Artifacts"
                                  ? "Learning Artifact"
                                  : resourceData?.storing_type === "Learning Resource"
                                  ? "Learning Resource"
                                  : "--"}
                              </div>
                            </div>
                          </li>
                        )}

                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Comments</div>
                            <div className="data-value"> {resourceData?.total_comments}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Likes</div>
                            <div className="data-value"> {resourceData?.total_likes}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Reviews</div>
                            <div className="data-value"> {resourceData?.total_reviews}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Shared</div>
                            <div className="data-value"> {resourceData?.total_shared}</div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Total Time Viewed</div>
                            <div className="data-value"> {resourceData?.total_time_viewed}</div>
                          </div>
                        </li>
                        <li className="data-item resourceUrl">
                          <div className="data-col">
                            <div className="data-label">Resource URL</div>
                            <div className="data-value" style={{ width: "50%" }}>
                              <a href={resourceData?.resource_url}>
                                {resourceData?.resource_url ? resourceData?.resource_url : "--"}
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Content Attached with Resource</div>
                            <ul>
                              {uploadContentName.length > 0
                                ? uploadContentName.map((val, i) => {
                                    return (
                                      <div>
                                        {val.status != "deleted" && (
                                          <div className="data-value">
                                            <li>
                                              <p className="mb-0">
                                                {" "}
                                                {val.imgurl
                                                  .split("/")[6]
                                                  .slice(0, val.imgurl.split("/")[6].indexOf("?"))}
                                              </p>
                                              {/* <div style={{clear:both}}></div> */}
                                              <button
                                                className="btn btn-md px-2 btn-primary "
                                                onClick={() => {
                                                  showContentPreview(
                                                    val.imgurl,
                                                    val.imgurl
                                                      .split("/")[6]
                                                      .slice(0, val.imgurl.split("/")[6].indexOf("?"))
                                                      .split(".")
                                                      .pop()
                                                  );
                                                  window.scrollTo({
                                                    bottom: 0,
                                                    behavior: "smooth",
                                                  });
                                                  if (document.body.classList.contains("savepage")) {
                                                    document.body.classList.add("removescroll");
                                                  } else {
                                                    document.body.classList.remove("removescroll");
                                                  }
                                                }}
                                              >
                                                preview
                                              </button>
                                            </li>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })
                                : "--"}
                            </ul>
                          </div>
                        </li>
                      </ul>
                      {isVisible && (
                        <div className="sharepopupOuter">
                          <div className="save-pop-up-container-inner">
                            <div className="pop-up-container">
                              <span
                                className="close-btn"
                                onClick={() => {
                                  setVisible(false);
                                  if (document.body.classList.contains("removescroll")) {
                                    document.body.classList.remove("removescroll");
                                  }
                                }}
                              >
                                <i className="fa fa-close closecs"></i>
                              </span>
                              <h3>Preview </h3>
                              <div className="input-popup2">
                                {contentPreview.imgurl &&
                                  (contentPreview.imgtype === "png" ||
                                    contentPreview.imgtype === "jpg" ||
                                    contentPreview.imgtype === "jpeg" ||
                                    contentPreview.imgtype === "jpeg") && (
                                    <img src={contentPreview.imgurl} width={200} heigth={200} />
                                  )}
                                {contentPreview.imgurl &&
                                  (contentPreview.imgtype === "mp4" ||
                                    contentPreview.imgtype === "mpeg" ||
                                    contentPreview.imgtype === "mpeg") && (
                                    <video width="500" height="340" controls controlsList="nodownload">
                                      <source
                                        src={contentPreview.imgurl}
                                        type={`video/${contentPreview.imgtype}`}
                                      ></source>
                                    </video>
                                  )}
                                {contentPreview.imgurl && contentPreview.imgtype === "mp3" && (
                                  <audio controls controlsList="nodownload">
                                    <source
                                      src={contentPreview.imgurl}
                                      type={`audio/${contentPreview.imgtype}`}
                                    ></source>
                                  </audio>
                                )}
                                {contentPreview.imgurl && contentPreview.imgtype === "pdf" && (
                                  <PdfViewer pdfurl={contentPreview.imgurl} />
                                )}
                              </div>

                              <div className="popup-btn-div"></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Card>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatepurchase}
                            className="form-control date-picker"
                            onChange={HandleFromDatePurchase}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{formDateErrorPurchase}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatepurchase}
                            className="form-control date-picker"
                            onChange={HandleToDatePurchase}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{toDateErrorPurchase}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button className="btn btn-md btn-primary" type="submit" onClick={handlepurchasefilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Purchases:{purchaseData?.length}</span>
                    </div>

                    {purchaseData && purchaseData.length > 0 ? (
                      <div>
                        <ReactDataTable data={purchaseData} columns={purchasecolumns} className="nk-tb-list" />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count1}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange1}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage1 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="nodatafounds">No Purchases Found</div>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatereview}
                            className="form-control date-picker"
                            onChange={HandleFromDateReview}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrorReview}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatereview}
                            className="form-control date-picker"
                            onChange={HandleToDateReview}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrorReview}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary" type="submit" onClick={handlereviewfilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Reviews:{reviewData.length}</span>
                    </div>
                    {reviewData && reviewData.length > 0 ? (
                      <div>
                        <ReactDataTable data={reviewData} columns={reviewcolumns} className="nk-tb-list" />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count10}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange10}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage10 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="#">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatereview}
                            className="form-control date-picker"
                            onChange={HandleFromDateReview}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatereview}
                            className="form-control date-picker"
                            onChange={HandleToDateReview}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary " type="submit">
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Reviews={resourceData && resourceData?.tbl_resource_reviews?.length}</span>
                    </div>
                    {resourceData && resourceData?.tbl_resource_reviews?.length > 0 ? (
                      <ReactDataTable
                        data={resourceData?.tbl_resource_reviews}
                        columns={reviewcolumns}
                        className="nk-tb-list"
                        // searchable={true}
                        pagination
                      />
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateshared}
                            className="form-control date-picker"
                            onChange={HandleFromDateShared}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrorshared}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateshared}
                            className="form-control date-picker"
                            onChange={HandleToDateShared}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrorshared}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Shared With:</strong>
                          </label>
                          <select className="form-control" value={sharedwith} onChange={handleSharedWith}>
                            <option value="">Select Shared With</option>
                            <option value="local">Matched Community</option>
                            <option value="friend">Friends</option>
                            <option value="group">Group</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlesharedfilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Shares:{sharedData?.length}</span>
                    </div>

                    {sharedData && sharedData?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={sharedData}
                          columns={sharedcolumns}
                          className="nk-tb-list"
                          //searchable={true}
                          // pagination
                        />
                        <div>
                          {" "}
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count2}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange2}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage2 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}

                    {/* {resourceData && resourceData.tbl_resource_shareds.length === 0 && <} */}
                  </TabPane>
                  <TabPane tabId="5">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatesaved}
                            className="form-control date-picker"
                            onChange={HandleFromDateSaved}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrorsaved}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatesaved}
                            className="form-control date-picker"
                            onChange={HandleToDateSaved}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrorsaved}</span>
                      </div>
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Status</strong>
                          </label>
                          <select className="form-control" onChange={handleSelectFor}>
                            <option value="">Status</option>
                            <option value="Past">Past</option>
                            <option value="In progress">In Progress</option>
                            <option value="For the future">Future</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Grade</strong>
                          </label>
                          <select className="form-control" onChange={handleGradeLevel}>
                            <option value="">Select Grade</option>
                            {gradeLevelData &&
                              gradeLevelData.map((val, i) => {
                                return <option value={val.id}>{val.name}</option>;
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Student </strong>
                          </label>
                          <select className="form-control" value={selectchild} onChange={handleselectchild}>
                            <option value="">Select Student</option>
                            {savechilddata &&
                              savechilddata.map((val, i) => {
                                return (
                                  <option value={val?.tbl_child?.id}>
                                    {val?.tbl_child?.firstName + " " + val?.tbl_child?.lastName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlesavedfilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Saves:{saveData && saveData?.length}</span>
                    </div>
                    {saveData && saveData?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={saveData}
                          columns={columns1}
                          className="nk-tb-list"
                          //searchable={true}
                          // pagination
                        />
                        <div>
                          {" "}
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count11}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange11}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage11 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                    {/* {resourceData && resourceData.tbl_resource_saveds.length === 0 && } */}
                  </TabPane>
                  <TabPane tabId="6">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatecomment}
                            className="form-control date-picker"
                            onChange={HandleFromDateComment}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrorcomment}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatecomment}
                            className="form-control date-picker"
                            onChange={HandleToDateComment}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrorcomment}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlecommentfilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Comments:{commentData?.length}</span>
                    </div>
                    {commentData && commentData?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={commentData}
                          columns={commentcolumns}
                          className="nk-tb-list"
                          //searchable={true}
                          // pagination
                        />
                        <div>
                          {" "}
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count3}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange3}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage3 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>
                  <TabPane tabId="7">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatelike}
                            className="form-control date-picker"
                            onChange={HandleFromDateLike}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrorlike}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatelike}
                            className="form-control date-picker"
                            onChange={HandleToDateLike}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrorlike}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0"></Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlelikefilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Likes:{likeData?.length}</span>
                    </div>
                    {likeData && likeData?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={likeData}
                          columns={likecolumns}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count4}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange4}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage4 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>
                  {/* <TabPane tabId="8">
                
                {resourceContent && resourceContent.length > 0 ? (
                    <ReactDataTable
                      data={resourceContent}
                      columns={contentcolumns}
                      className="nk-tb-list"
                      searchable={true}
                      pagination
                    />
                  ) : (
                    <div>No data found</div>
                  )}
                </TabPane> */}
                  <TabPane tabId="9">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdatementoring}
                            className="form-control date-picker"
                            onChange={HandleFromDateMentoring}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErrormentoring}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodatementoring}
                            className="form-control date-picker"
                            onChange={HandleToDateMentoring}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErrormentoring}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handlementoringfilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Scheduled Sessions:{mentoringData?.length}</span>
                    </div>

                    {mentoringData && mentoringData?.length > 0 ? (
                      <div>
                        <ReactDataTable data={mentoringData} columns={mentoringcolumns} className="nk-tb-list" />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count5}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange5}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage5 - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="10">
                    {/* <p>Tab-10</p> */}


                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateGroupResource}
                            className="form-control date-picker"
                            onChange={HandleFromDateGroupResource}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        { <span className="error">{fromdateErrorGroupResource}</span> }
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateGroupResource}
                            className="form-control date-picker"
                            onChange={HandleToDateGroupResource}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        { <span className="error">{todateErrorGroupResource}</span> }
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handleGroupResourcefilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    {groupListResource && groupListResource?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={groupListResource}
                          columns={columnsGroup}
                          className="nk-tb-list"
                          // searchable={true}
                          // pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countGroup}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeGroup}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="11">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateoffensive}
                            className="form-control date-picker"
                            onChange={HandleFromDateOffensive}
                          />
                          {/* <input type="date"  placeholder="enter" name ="formdate" className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                        </div>
                        <span className="error">{fromdateErroroffensive}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateoffensive}
                            className="form-control date-picker"
                            onChange={HandleToDateOffensive}
                          />
                          {/* <input type="date"  name ="todate" className="form-control" value={selectedtodate} onChange={HandleToDate}></input> */}
                        </div>
                        <span className="error">{todateErroroffensive}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handleoffensivefilter}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>Total Offences:{offensiveData?.length}</span>
                    </div>
                    {offensiveData && offensiveData?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={offensiveData}
                          columns={offensivecolumns}
                          className="nk-tb-list"
                          // searchable={true}
                          //pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count6}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange6}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage6-1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateResourceGetInfo}
                            className="form-control date-picker"
                            onChange={HandleFromDateResourceGetInfo}
                          />
                        </div>
                        <span className="error">{fromdateErrorResourceGetInfo}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateResourceGetInfo}
                            className="form-control date-picker"
                            onChange={HandleToDateResourceGetInfo}
                          />
                        </div>
                        <span className="error">{todateErrorResourceGetInfo}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0">&nbsp;</Label>
                          <button
                            className="btn btn-md btn-primary "
                            type="submit"
                            onClick={handleResourceGetInfoSearch}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>
                        <strong>Total Info Request:{totalCountInfo}</strong>
                      </span>
                    </div>
                    {resourceGetInfo && resourceGetInfo?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={resourceGetInfo}
                          columns={columnsResourceGetInfo}
                          className="nk-tb-list"
                          // searchable={true}
                          //pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countResourceGetInfo}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeResourceGetInfo}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="13">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateResourceEnrol}
                            className="form-control date-picker"
                            onChange={HandleFromDateResourceEnrol}
                          />
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateResourceEnrol}
                            className="form-control date-picker"
                            onChange={HandleToDateResourceEnrol}
                          />
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0"></Label>
                          <button className="btn btn-md btn-primary " type="submit" onClick={handleResourceEnrolSearch}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>
                        <strong>Total Enroll Request:{totalCountEnroll}</strong>
                      </span>
                    </div>
                    {resourceEnroll && resourceEnroll?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={resourceEnroll}
                          columns={columnsResourceEnroll}
                          className="nk-tb-list"
                          // searchable={true}
                          //pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countResourceEnroll}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeResourceEnroll}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>

                  <TabPane tabId="14">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>

                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdateResourceSignup}
                            className="form-control date-picker"
                            onChange={HandleFromDateResourceSignup}
                          />
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodateResourceSignup}
                            className="form-control date-picker"
                            onChange={HandleToDateResourceSignup}
                          />
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-4">
                        <div className="outerTypeBox form-control border-0 px-0">
                          <Label className="w-100 mb-0"></Label>
                          <button
                            className="btn btn-md btn-primary "
                            type="submit"
                            onClick={handleResourceSignupSearch}
                          >
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="totalRight">
                      <span>
                        <strong>Total Signup Request:{totalCountSignup}</strong>
                      </span>
                    </div>
                    {affiliatedSignup && affiliatedSignup?.length > 0 ? (
                      <div>
                        <ReactDataTable
                          data={affiliatedSignup}
                          columns={columnsAffiliateSignup}
                          className="nk-tb-list"
                          // searchable={true}
                          //pagination
                        />
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={countAffiliateSignup}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChangeAffiliateSignup}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPageAffiliateSignup - 1}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="nodatafounds">No data found</div>
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </PreviewCard>
        )}
        <>
          <Modal isOpen={isOpen} toggle={openModal}>
            <ModalHeader
              toggle={openModal}
              close={
                <button className="close" onClick={openModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Review Details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}

                          {/* </div> */}
                          <ul className="nk-support">
                            <p>Student's Reviews</p>
                            {childReviews.tbl_resource_child_reviews &&
                              childReviews.tbl_resource_child_reviews?.length > 0 &&
                              childReviews.tbl_resource_child_reviews.map((val, i) => {
                                return (
                                  <li className="nk-support-item" key={i}>
                                    <UserAvatar
                                      image={
                                        val.tbl_child && val.tbl_child.photo ? S3_BUCKET_URL + val.tbl_child.photo : ""
                                      }
                                      theme={colorThemes[1]}
                                      text={findUpper(
                                        val.tbl_child.firstName.charAt(0).toUpperCase() +
                                          val.tbl_child.firstName.slice(1) +
                                          " " +
                                          val.tbl_child.lastName.charAt(0).toUpperCase() +
                                          val.tbl_child.lastName.slice(1)
                                      )}
                                    />
                                    <div className="nk-support-content">
                                      <div className="title">
                                        <span>
                                          {" "}
                                          {val.tbl_child ? val.tbl_child.firstName : ""}{" "}
                                          {val.tbl_child ? val.tbl_child.lastName : ""}
                                        </span>
                                        <div className="rating">
                                          {star.map((temp) => (
                                            <>
                                              {temp == val.rating ? (
                                                <div className="star">
                                                  <FontAwesomeIcon icon={faStarHalfAlt} />
                                                </div>
                                              ) : temp < val.rating ? (
                                                <div className="star">
                                                  <FontAwesomeIcon icon={faStar} />
                                                </div>
                                              ) : (
                                                <div className="star unfilled">
                                                  <FontAwesomeIcon icon={faStar} />
                                                </div>
                                              )}
                                            </>
                                          ))}
                                        </div>
                                      </div>

                                      <span className="time">
                                        Posted on : {moment(val.created_at).format("MM-DD-YYYY HH:mm")}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                          <div className="card-title-group">
                            {childReviews.tbl_resource_child_reviews &&
                              childReviews.tbl_resource_child_reviews?.length === 0 && (
                                <CardTitle>
                                  {" "}
                                  <h6 className="title">No Student Reviews Found</h6>{" "}
                                </CardTitle>
                              )}
                          </div>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <p>Comment: {childReviews.message}</p>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={openModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isPayOpen} toggle={paymentModal}>
            <ModalHeader
              toggle={paymentModal}
              close={
                <button className="close" onClick={paymentModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Payment Details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordered card">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}
                          <div className="card-title-group"></div>
                          {/* </div> */}
                          <table>
                            <tr>
                              <td width="200">Invoice No </td>
                              <td>{paymentDetails.invoice_number ? paymentDetails.invoice_number : "--"} </td>
                            </tr>
                            <tr>
                              <td>Payment Reference No </td>
                              <td>
                                {paymentDetails.payment_reference_no ? paymentDetails.payment_reference_no : "--"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Payment Amount </td>
                              <td>
                                {paymentDetails?.payment_amount
                                  ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(paymentDetails?.payment_amount)
                                  : "$0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Tax Amount </td>
                              <td>
                                {paymentDetails.tax_amount
                                  ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(paymentDetails.tax_amount)
                                  : "$0.00"}
                              </td>
                            </tr>

                            <tr>
                              <td>Transaction Fees </td>
                              <td>
                                {paymentDetails.transaction_fee
                                  ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(paymentDetails.transaction_fee)
                                  : "$0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Total Amount </td>
                              <td>
                                {paymentDetails?.total_amount
                                  ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(paymentDetails?.total_amount)
                                  : "$0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Admin Fees </td>
                              <td>
                                {paymentDetails.admin_fee
                                  ? new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(paymentDetails.admin_fee)
                                  : "$0.00"}
                              </td>
                            </tr>
                            <tr>
                              <td>Payment Method </td>
                              <td>{paymentDetails.payment_method ? paymentDetails.payment_method : ""} </td>
                            </tr>
                            <tr>
                              {/* <td>Invoice </td><td>
                                                    <a href={`${S3_BUCKET_URL}${paymentDetails.invoice_pdf_link}`} target="_blank"> View </a>
                                                   
                                                   </td> */}
                              <td>Invoice </td>
                              <td>
                                {paymentDetails.invoice_pdf_link ? (
                                  <a href={`${S3_BUCKET_URL}${paymentDetails.invoice_pdf_link}`} target="_blank">
                                    View
                                  </a>
                                ) : (
                                  <span>No Invoice Available</span>
                                )}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={paymentModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isSharedOpen} toggle={shareModal}>
            <ModalHeader
              toggle={shareModal}
              close={
                <button className="close" onClick={shareModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Shared details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordereds">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}
                          <div className="card-title-group"></div>
                          {/* </div> */}
                          <table>
                            <tr>
                              <td width="200">
                                <h5>(Friends)</h5>
                              </td>
                              <td width="200">
                                <h5>(Groups)</h5>
                              </td>
                            </tr>

                            {friendList?.length === 0 && grouplist?.length === 0 ? (
                              <>
                                <tr>
                                  <td width="200">
                                    <h5>-All Friends-</h5>
                                  </td>
                                  <td width="200">
                                    <h5>-All Groups-</h5>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td>
                                    {friendList && friendList?.length > 0
                                      ? friendList.map((val, i) => {
                                          return (
                                            <>
                                              {val.firstName} {val.lastName}
                                              {i < friendList?.length - 1 ? "," : ""}
                                            </>
                                          );
                                        })
                                      : "--"}
                                  </td>
                                  <td>
                                    {grouplist && grouplist?.length > 0
                                      ? grouplist.map((val, i) => {
                                          return (
                                            <>
                                              {val?.group_name} {i < grouplist?.length - 1 ? "," : ""}
                                            </>
                                          );
                                        })
                                      : "--"}
                                  </td>
                                </tr>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={shareModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={isSaveOpen} toggle={saveModal}>
            <ModalHeader
              toggle={saveModal}
              close={
                <button className="close" onClick={saveModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Saved Details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordereds">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}
                          <div className="card-title-group"></div>
                          {/* </div> */}
                          <table>
                            <tr>
                              <td width="200">Saved for: </td>
                              <td>{savedDetails.resource_for ? savedDetails.resource_for : ""} </td>
                            </tr>

                            <tr>
                              <td>Grade Level: </td>
                              <td>
                                {savedDetails.tbl_resource_save_grade_mappings &&
                                  savedDetails.tbl_resource_save_grade_mappings.map((val, i) => {
                                    return (
                                      <>
                                        {val.tbl_grade_level ? val.tbl_grade_level.name : "--"}
                                        {i < savedDetails.tbl_resource_save_grade_mappings?.length - 1 ? "," : ""}
                                      </>
                                    );
                                  })}
                              </td>
                            </tr>
                            <tr>
                              <td>Viewable by Friends: </td>
                              <td>
                                {savedDetails.viewable_by_my_friends ? savedDetails.viewable_by_my_friends : "--"}{" "}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={saveModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isMentoringOpen} toggle={mentoringModal}>
            <ModalHeader
              toggle={mentoringModal}
              close={
                <button className="close" onClick={mentoringModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Mentoring Details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordereds">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}
                          <div className="card-title-group"></div>
                          {/* </div> */}
                          <table>
                            <tbody className="nk-support">
                              <tr>
                                <td width="50%">Duration: </td>
                                <td>{mentoringDetails.duration ? mentoringDetails.duration + " Minutes" : "--"} </td>
                              </tr>

                              <tr>
                                <td width="50%">Price per duration: </td>
                                <td>
                                  {mentoringDetails?.price_per_duration
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(mentoringDetails.price_per_duration)
                                    : "$0.00"}{" "}
                                </td>
                              </tr>
                              <tr>
                                {/* <td>Booking Type: </td><td>{mentoringDetails?.session_type == "auto" ? "Reoccuring booking " : "Single booking"}{" "}
                                </td> */}
                              </tr>
                              <tr>
                                <td>Total Amount: </td>
                                <td>
                                  {mentoringDetails.total_amount
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(mentoringDetails.total_amount)
                                    : "$0.00"}{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={mentoringModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={isOffensiveOpen} toggle={offensiveModal}>
            <ModalHeader
              toggle={offensiveModal}
              close={
                <button className="close" onClick={offensiveModal}>
                  <Icon name="cross" />
                </button>
              }
            >
              Offense Details
            </ModalHeader>
            <ModalBody>
              <Card className="card-bordereds">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {/* <div className="card-inner border-bottom"> */}
                          <div className="card-title-group"></div>
                          {/* </div> */}
                          <table>
                            <tbody className="nk-support">
                              <tr>
                                <td width="50%">Reason: </td>
                                <td width="50%">{offensiveDetails.reason ? offensiveDetails.reason : ""} </td>
                              </tr>
                              <tr>
                                <td width="50%">Comment: </td>
                                <td width="50%">{offensiveDetails.comment ? offensiveDetails.comment : ""} </td>
                              </tr>
                              <tr>
                                <td>Resolved Remarks: </td>
                                <td>{offensiveDetails.resolved_remark ? offensiveDetails.resolved_remark : ""} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={offensiveModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={signupDetailModal} toggle={SignupModalFunction}>
            <ModalHeader
              toggle={SignupModalFunction}
              close={
                <button className="close" onClick={SignupModalFunction}>
                  <Icon name="cross" />
                </button>
              }
            >
              Signup Details
            </ModalHeader>
            <ModalBody>
              <Card className="">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          <div className="card-title-group"></div>

                          <table>
                            <tr>
                              <td width="200">Session Date: </td>
                              <td width="200">Session Start Time: </td>
                              <td width="200">Session End Time: </td>
                            </tr>
                            {signUpDetails?.tbl_user_mentoring_session_datetimes &&
                              signUpDetails?.tbl_user_mentoring_session_datetimes.map((val, i) => {
                                return (
                                  <>
                                    <tr>
                                      {" "}
                                      <td width="200">
                                        {val?.session_date ? moment(val?.session_date).format("MM-DD-YYYY") : "--"}{" "}
                                      </td>
                                      <td width="200">{val?.session_time ? getTime(val?.session_time) : "--"} </td>
                                      <td width="200">
                                        {val?.session_end_time ? getTime(val?.session_end_time) : "--"}{" "}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}

                            {/* <tr>
                                            
                                                   
                                                    <td>Price per duration: </td><td>{mentoringDetails?.price_per_duration ?  new Intl.NumberFormat("en-US",{
     style:"currency",
    currency:"USD",
  }).format(mentoringDetails.price_per_duration) : "$0.00"}{" "}
                                                     
                                                    </td>
                                                  
                                            </tr> */}

                            {/* <tr>
                                          
                                                    <td>Total Amount: </td><td>{mentoringDetails.total_amount ? new Intl.NumberFormat("en-US",{
     style:"currency",
    currency:"USD",
  }).format(mentoringDetails.total_amount) : "$0.00"}{" "}
                                                    </td>
                                                
                                            </tr> */}
                          </table>
                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
            </ModalBody>
            <ModalFooter className="">
              <Button color="primary" onClick={SignupModalFunction}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
      </Content>
    </>
  );
}

export default ViewResource;
