import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { ToastContainer, toast } from "react-toastify";
import { BlockHead, BlockHeadContent, BlockTitle } from "../../../components/Component";
import { Card, Collapse, Input } from "reactstrap";
import { useEffect } from "react";
import { makeGetRequest, makePostRequest } from "../../../service/api";
import { URLS } from "../../../constants";

const NotificationSettings = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [currentFocusId, setcurrentFocusId] = useState("");
  const [loader, setLoader] = useState(false);

  const getNotification = (data) => {
    if (data) setLoader(true);
    makeGetRequest(URLS.GET_NOTIFICATION, null, null)
      .then((res) => {
        console.log("response", res);
        if (res.code == 200) {
          setNotificationData(res?.data);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  const onUpdate = (v) => {
    const body = {};
    // setLoader(true)
    console.log("body",v)

    if(v.id=="56")
      {
        v.meassage_email=v.meassage_email.replace( "{GroupAdmin}","{userGroupName}")
        v.message_push=v.message_push.replace( "{GroupAdmin}","{userGroupName}")
        v.message_sms=v.message_sms.replace( "{GroupAdmin}","{userGroupName}")
        v.message_system=v.message_system.replace( "{GroupAdmin}","{userGroupName}")

      }
      else
      if(v.id=="57")
        {
        v.meassage_email=v?.meassage_email.replace( "{GroupAdmin}","{userName}").replace( "{matchedUser}","{userGroupName}")
        v.message_push=v?.message_push.replace( "{GroupAdmin}","{userName}").replace( "{matchedUser}","{userGroupName}")
        v.message_sms=v?.message_sms.replace( "{GroupAdmin}","{userName}").replace( "{matchedUser}","{userGroupName}")
        v.message_system=v?.message_system.replace( "{GroupAdmin}","{userName}").replace( "{matchedUser}","{userGroupName}")
        }
        else
     if(v.id=="60")
      {
      v.meassage_email=v?.meassage_email.replace( "{GroupAdmin}","{userName}").replace("{groupUser}","{userGroupName}")
      v.message_push=v?.message_push.replace( "{GroupAdmin}","{userName}").replace("{groupUser}","{userGroupName}")
      v.message_sms=v?.message_sms.replace( "{GroupAdmin}","{userName}").replace("{groupUser}","{userGroupName}")
      v.message_system=v?.message_system.replace( "{GroupAdmin}","{userName}").replace("{groupUser}","{userGroupName}")
      }
      else
     if(v.id=="61"){
      v.meassage_email=v?.meassage_email.replace( "{GroupAdmin}","{userGroupName}")
      v.message_push=v?.message_push.replace( "{GroupAdmin}","{userGroupName}")
      v.message_sms=v?.message_sms.replace( "{GroupAdmin}","{userGroupName}")
      v.message_system=v?.message_system.replace( "{GroupAdmin}","{userGroupName}")
     }
    else
     if(v.id=="65"){
      v.meassage_email=v?.meassage_email.replace( "{GroupAdmin}","{userGroupName}")
      v.message_push=v?.message_push.replace( "{GroupAdmin}","{userGroupName}")
      v.message_sms=v?.message_sms.replace( "{GroupAdmin}","{userGroupName}")
      v.message_system=v?.message_system.replace( "{GroupAdmin}","{userGroupName}")
     }
    else
     if(v.id=="24"){
      v.meassage_email=v?.meassage_email.replace("{friendName}","{share_with_name}" )
      v.message_push=v?.message_push.replace("{friendName}","{share_with_name}" )
      v.message_sms=v?.message_sms.replace("{friendName}","{share_with_name}" )
      v.message_system=v?.message_system.replace("{friendName}","{share_with_name}" )
     }
    else
     if(v.id=="72"){
      v.meassage_email=v?.meassage_email.replace("{customerName}","{buy_user_name}")
      v.message_push=v?.message_push.replace("{customerName}","{buy_user_name}")
      v.message_sms=v?.message_sms.replace("{customerName}","{buy_user_name}")
      v.message_system=v?.message_system.replace("{customerName}","{buy_user_name}")

     }

    else
     if(v.id=="11")
      {
      v.meassage_email=v?.meassage_email.replace("{sold_by_user}","{mentorName}" )
      v.message_push=v?.message_push.replace("{sold_by_user}","{mentorName}" )
      v.message_sms=v?.message_sms.replace("{sold_by_user}","{mentorName}" )
      v.message_system=v?.message_system.replace("{sold_by_user}","{mentorName}" )
      }
    

    console.log("body",v)
    
    makePostRequest(URLS.UPDATE_NOTIFICATION, null, v, null)
      .then((res) => {
        console.log("response", res);
        if (res.code == 200) {
          toast.success(res?.message);
          getNotification(false);
        }
      })
      .catch((error) => {
        // setLoader(false)
        console.log(error);
      });
  };

  useEffect(() => {
    getNotification(true);
  }, []);

  const handleStatus = (p_id, c_id, name) => {
    console.log("name", p_id, c_id, name);
    const data = [...notificationData];
    const newData = data?.map((v) => {
      if (v?.id == p_id) {
        let dd = v?.tbl_notification_masters?.map((c) => {
          if (c?.id == c_id) {
            return { ...c, [name]: c[name] == "enable" ? "disable" : "enable" };
          } else {
            return c;
          }
        });
        return { ...v, tbl_notification_masters: dd };
      } else {
        return v;
      }
    });
    console.log("newData", newData);
    setNotificationData(newData);
  };

  const selectDynamic = (p_id, c_id, name) => {
    const inputField = document.getElementById(currentFocusId);
    if (inputField) {
      inputField.focus();
      let id = currentFocusId?.split(".")[1];
      var cursorPosition = inputField.selectionStart;
      var currentValue = inputField.value;
      var updatedValue = currentValue.substring(0, cursorPosition) + name + currentValue.substring(cursorPosition);

      const data = [...notificationData];
      const newData = data?.map((v) => {
        if (v?.id == p_id) {
          let dd = v?.tbl_notification_masters?.map((c) => {
            if (c?.id == c_id) {
              return { ...c, [id]: updatedValue };
            } else {
              return c;
            }
          });
          return { ...v, tbl_notification_masters: dd };
        } else {
          return v;
        }
      });
      console.log("newData", newData);
      setNotificationData(newData);
      // inputField.value = name
    }
  };

  const toggleOuter = (id) => {
    let data = [...notificationData];
    let newData = data?.map((v) => {
      if (v?.id == id) {
        return { ...v, isOpen: !v?.isOpen };
      } else {
        return v;
      }
    });

    setNotificationData(newData);
  };

  const toggleInner = (p_id, c_id) => {
    const data = [...notificationData];
    const newData = data?.map((v) => {
      if (v?.id == p_id) {
        let dd = v?.tbl_notification_masters?.map((c) => {
          if (c?.id == c_id) {
            return { ...c, isOpen: !c?.isOpen };
          } else {
            return c;
          }
        });
        return { ...v, tbl_notification_masters: dd };
      } else {
        return v;
      }
    });
    console.log("newData", newData);
    setNotificationData(newData);
  };

  const onMessageChange = (e, p_id, c_id) => {
    const data = [...notificationData];
    let id = currentFocusId?.split(".")[1];
    const newData = data?.map((v) => {
      if (v?.id == p_id) {
        let dd = v?.tbl_notification_masters?.map((c) => {
          if (c?.id == c_id) {
            return { ...c, [id]: e.target.value };
          } else {
            return c;
          }
        });
        return { ...v, tbl_notification_masters: dd };
      } else {
        return v;
      }
    });
    console.log("newData", newData);
    setNotificationData(newData);
  };
  console.log("notificationData", notificationData);
  return (
    <React.Fragment>
      <Head title="Notification Setting"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Notification Setting</h3>
        {/* <BlockTitle tag="h4">Notification Setting</BlockTitle> */}

        <Card className="card-bordered">
          {loader ? (
            <div className="overflowloader" id="preload">
              <div className="circle-square" style={{ marginTop: "250px" }}>
                <div className="red" style={{ background: "#AAC0CE" }}></div>
                <div className="blue" style={{ background: "#265472" }}></div>
                <div className="green" style={{ background: "#F19357" }}></div>
                <div className="yellow" style={{ background: "#F0B285" }}></div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-inner">
                <BlockHead size="lg">
                  <BlockHeadContent>
                    <div className="accordion">
                      {notificationData?.map((v) => (
                        <div className="accordion-item">
                          <div className="accordion-head collapsed cursorPointer" onClick={() => toggleOuter(v?.id)}>
                            <h6 className="title">{v?.notification_group_name}</h6>
                            <span className="accordion-icon"></span>
                          </div>
                          <Collapse className="accordion-body" isOpen={v?.isOpen}>
                            <div className="accordion-inner">
                              {/* ------------------------------------------- */}

                              <div className="accordion">
                                {v?.tbl_notification_masters?.map((g) => (
                                  <div className="accordion-item">
                                    <div
                                      className="accordion-head collapsed cursorPointer"
                                      onClick={() => toggleInner(v?.id, g?.id)}
                                    >
                                      <h6 className="title">{g?.meassge_type}</h6>
                                      <span className="accordion-icon"></span>
                                    </div>
                                    <Collapse className="accordion-body" isOpen={g?.isOpen}>
                                      <div className="accordion-inner">
                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">Email Subject</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              type="textarea"
                                              rows="1"
                                              id={v?.id + g?.id + ".subject"}
                                              value={g?.subject}
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                            />
                                          </div>
                                          <div className="" style={{ width: "10%" }}>
                                            {/* <input
                                          className="form-check-input cursorPointer"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                          checked={g?.message_push_status == "enable" ? true : false}
                                          onChange={() => handleStatus(v?.id, g?.id, "message_push_status")}
                                        /> */}
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">Email</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              type="textarea"
                                              id={v?.id + g?.id + ".meassage_email"}
                                              value={
                                                 g.id == "57"
                                                  ? g?.meassage_email.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{matchedUser}"):
                                                  g.id == "60"
                                                  ? g?.meassage_email.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{groupUser}")
                                                  : g.id == "61"
                                                  ? g?.meassage_email.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "65"
                                                  ? g?.meassage_email.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "24"
                                                  ? g?.meassage_email.replace("{share_with_name}", "{friendName}")
                                                  : g.id == "72"
                                                  ? g?.meassage_email.replace("{buy_user_name}", "{customerName}")
                                                  : g.id == "11"
                                                  ? g?.meassage_email.replace("{sold_by_user}", "{mentorName}")
                                                  : g?.meassage_email
                                              }
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                            />
                                          </div>
                                          <div
                                            className="notification-switch form-check form-switch form-switch-sm"
                                            style={{ width: "10%" }}
                                          >
                                            <input
                                              style={{ margin: "0 auto" }}
                                              className="form-check-input cursorPointer"
                                              type="checkbox"
                                              id="flexSwitchCheckDefault"
                                              checked={g?.message_email_status == "enable" ? true : false}
                                              onChange={() => handleStatus(v?.id, g?.id, "message_email_status")}
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">Push Subject</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              type="textarea"
                                              rows="1"
                                              id={v?.id + g?.id + ".push_subject"}
                                              value={g?.push_subject}
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                            />
                                          </div>
                                          <div className="" style={{ width: "10%" }}>
                                            {/* <input
                                          className="form-check-input cursorPointer"
                                          type="checkbox"
                                          id="flexSwitchCheckDefault"
                                          checked={g?.message_push_status == "enable" ? true : false}
                                          onChange={() => handleStatus(v?.id, g?.id, "message_push_status")}
                                        /> */}
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">Push</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              type="textarea"
                                              rows="1"
                                              id={v?.id + g?.id + ".message_push"}
                                              value={
                                                g.id == "57"
                                                  ? g?.message_push.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{matchedUser}")
                                                  

                                                  : g.id == "60"
                                                  ? g?.message_push.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{groupUser}")
                                                  
                                                  : g.id == "61"
                                                  ? g?.message_push.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "65"
                                                  ? g?.message_push.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "24"
                                                  ? g?.message_push.replace("{share_with_name}", "{friendName}")
                                                  : g.id == "72"
                                                  ? g?.message_push.replace("{buy_user_name}", "{customerName}")
                                                  : g.id == "11"
                                                  ? g?.message_push.replace("{sold_by_user}", "{mentorName}")
                                                  : g?.message_push
                                              }
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                            />
                                          </div>
                                          <div
                                            className="notification-switch form-check form-switch form-switch-sm"
                                            style={{ width: "10%" }}
                                          >
                                            <input
                                              style={{ margin: "0 auto" }}
                                              className="form-check-input cursorPointer"
                                              type="checkbox"
                                              id="flexSwitchCheckDefault"
                                              checked={g?.message_push_status == "enable" ? true : false}
                                              onChange={() => handleStatus(v?.id, g?.id, "message_push_status")}
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">SMS</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              id={v?.id + g?.id + ".message_sms"}
                                              type="textarea"
                                              value={
                                                 g.id == "57"
                                                  ? g?.message_sms.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{matchedUser}")
                                                  : g.id == "60"
                                                  ? g?.message_sms.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{groupUser}")
                                                  : g.id == "61"
                                                  ? g?.message_sms.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "65"
                                                  ? g?.message_sms.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "24"
                                                  ? g?.message_sms.replace("{share_with_name}", "{friendName}")
                                                  : g.id == "72"
                                                  ? g?.message_sms.replace("{buy_user_name}", "{customerName}")
                                                  : g.id == "11"
                                                  ? g?.message_sms.replace("{sold_by_user}", "{mentorName}")
                                                  : g?.message_sms
                                              }
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                            />
                                          </div>
                                          <div
                                            className="notification-switch form-check form-switch form-switch-sm"
                                            style={{ width: "10%" }}
                                          >
                                            <input
                                              style={{ margin: "0 auto" }}
                                              className="form-check-input cursorPointer"
                                              type="checkbox"
                                              id="flexSwitchCheckDefault"
                                              checked={g?.message_sms_status == "enable" ? true : false}
                                              onChange={() => handleStatus(v?.id, g?.id, "message_sms_status")}
                                            />
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ms-3 my-2">
                                          <div style={{ width: "20%" }}>
                                            <h6 className="title m-0">System</h6>
                                          </div>
                                          <div style={{ width: "70%" }}>
                                            <Input
                                              name="name"
                                              placeholder="search..."
                                              type="textarea"
                                              id={v?.id + g?.id + ".message_system"}
                                              value={
                                                 g.id == "57"
                                                  ? g?.message_system.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{matchedUser}")
                                                  : g.id == "60"
                                                  ? g?.message_system.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{groupUser}")
                                                  : g.id == "61"
                                                  ? g?.message_system.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "65"
                                                  ? g?.message_system.replace("{userGroupName}", "{GroupAdmin}")
                                                  : g.id == "24"
                                                  ? g?.message_system.replace("{share_with_name}", "{friendName}")
                                                  : g.id == "72"
                                                  ? g?.message_system.replace("{buy_user_name}", "{customerName}")
                                                  : g.id == "11"
                                                  ? g?.message_system.replace("{sold_by_user}", "{mentorName}")
                                                  : g?.message_system
                                              }
                                              onFocus={(e) => setcurrentFocusId(e?.target.id)}
                                              onChange={(e) => onMessageChange(e, v?.id, g?.id)}
                                            />
                                          </div>
                                          <div
                                            className="notification-switch form-check form-switch form-switch-sm"
                                            style={{ width: "10%" }}
                                          >
                                            <input
                                              style={{ margin: "0 auto" }}
                                              className="form-check-input cursorPointer"
                                              type="checkbox"
                                              id="flexSwitchCheckDefault"
                                              checked={g?.message_system_status == "enable" ? true : false}
                                              onChange={() => handleStatus(v?.id, g?.id, "message_system_status")}
                                            />
                                          </div>
                                        </div>
                                        <div className="mt-3 ms-3 text-bold">
                                          {g?.dynamice_parmeter ? (
                                            <>
                                              Message parameter -{" "}
                                              {g?.dynamice_parmeter?.split(",")?.map((a) => (
                                                <span
                                                  className="cursorPointer"
                                                  onClick={(e) => selectDynamic(v?.id, g?.id, a)}
                                                >
                                                 { g.id == "57"
                                                    ? a.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{matchedUser}")
                                                    : g.id == "60"
                                                    ? a.replace("{userName}", "{GroupAdmin}").replace("{userGroupName}", "{groupUser}")
                                                    : g.id == "61"
                                                    ? a.replace("{userGroupName}", "{GroupAdmin}")
                                                    : g.id == "65"
                                                    ? a.replace("{userGroupName}", "{GroupAdmin}")
                                                    : g.id == "24"
                                                    ? a.replace("{share_with_name}", "{friendName}")
                                                    : g.id == "72"
                                                    ? a.replace("{buy_user_name}", "{customerName}")
                                                    : g.id == "11"
                                                    ? a.replace("{sold_by_user}", "{mentorName}")
                                                    : a}{" "}
                                                </span>
                                              ))}
                                            </>
                                          ) : null}
                                        </div>

                                        <div className="mt-2 ms-3 text-bold">
                                          <span style={{ color: "#FF7F7F" }}>
                                            Note : <ol>
                                            <li>Dynamic variable can't be changed</li>
                                            <li>Can't add same dynamic variable twice</li>

                                            </ol>
                                          </span>
                                        </div>

                                        <div className="text-center">
                                          <button
                                            className="btn btn-md btn-primary mt-2"
                                            // type="button"
                                            onClick={() => onUpdate(g)}
                                          >
                                            {" "}
                                            Submit{" "}
                                          </button>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                ))}
                              </div>

                              {/* ------------------------------------------- */}
                            </div>
                          </Collapse>
                        </div>
                      ))}
                    </div>
                  </BlockHeadContent>
                </BlockHead>
              </div>
            </>
          )}
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default NotificationSettings;
