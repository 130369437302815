import React, { useState, useEffect, useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { URLS } from "../../../constants/urls";
import DatePicker from "react-datepicker";

import { Input, Modal, ModalBody } from "reactstrap";
import Select from "react-select";
import { BlockHead, BlockHeadContent, Icon, ReactDataTable } from "../../../components/Component";
import { ModalFooter, ModalHeader, CardTitle } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetailsFromToken } from "../../../service/auth";
import { makePostRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { FaFilter } from "react-icons/fa";
const ExcelJS = require("exceljs");

const ListOffensive = () => {
  const dataToPass = { Offensive_list_status: "offense" };
  const [offensiveAllData, setOffensiveAllData] = useState([]);
  const [offensiveAllDataShow, setOffensiveAllDataShow] = useState([]);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const [notifyCount, setNotifyCount] = useState(0);
  const [OffensiveData, setOffensiveData] = useState({});
  const [showComment, setShowComment] = useState([]);
  let userAuth = getUserDetailsFromToken();
  const [resolvedBy, setResolvedBy] = useState("");
  const [saveResolution, setsaveResolution] = useState("");
  const [resourceComment, setResourceComment] = useState("");
  const [errorRequirement, setErrorRequirement] = useState("");
  const [showResourceBlock, setShowResourceBlock] = useState(false);
  const [expressionTextError, setErrorExpressionText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState();
  const [pageCount, setPageCount] = useState(null);
  const [search, setSearch] = useState("");
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [dropdownPage, setDropdownPage] = useState(1);
  const [selectedResourse, setselectedResourse] = useState();
  const dropdownRef = useRef(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortoffensive, setSortOffensive] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");

  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);

  const [clearState, setClearState] = useState(false);

  let offensedata = JSON.parse(sessionStorage.getItem("offense"));

  const getResourceDropdown = () => {
    makePostRequest(URLS.GET_RESOURCE_OFFENSIVE, null, { page_number: dropdownPage }, null)
      .then((res) => {
        //console.log("response1", res);
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getResourceDropdown();
  }, []);

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (target.scrollTop === target.scrollHeight - target.clientHeight) {
      getResourceDropdown();
    }
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const openModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const openModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const ShowHideSelect = () => {
    setShow(!show);
  };

  const cancelModal = () => {
    setIsOpen2(false);
    setIsOpen(false);
    setIsOpen1(false);
  };

  const HandleFromDate = (e) => {
    //console.log(e);
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
    // console.log(selectedfromdate, "fdate");

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        // pagenumberoffense: currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: search,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: e ? moment(e).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleToDate = (e) => {
    //console.log(e);
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);

    settoDateError("");

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        // pagenumberoffense: currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: search,

        endDate: e ? moment(e).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };

  const handleResourceName = (e) => {
    setselectedResourse(e);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        //pagenumberoffense:  currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: e,
        searchbyuser: search,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };

  const handleSearchByReportedName = (e) => {
    setSearch(e.target.value);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        //pagenumberoffense:  currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: e.target.value,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };

  const HandleStatus = (e) => {
    setSelectedStatus(e.target.value);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        //pagenumberoffense:  currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: e.target.value,
        resourceoffense: selectedResourse,
        searchbyuser: search,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    // sessionStorage.setItem("limitvalueoffense",e.target.value)
    setCurrentPage(1);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: e.target.value,
        pagenumberoffense: currentPage,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: search,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  const HandleSortOffensive = (e) => {
    setSortOffensive(e.target.value);
    // sessionStorage.setItem("sortvalueoffense",e.target.value)
    setCurrentPage(1);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        // pagenumberoffense: currentPage,
        sortvalueoffense: e.target.value,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: search,
        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };

  useEffect(() => {
    setSelectedToDate(offensedata.endDate);
    setSelectedfromDate(offensedata.startDate);
    setlimitvalue(offensedata.limitvalueoffense);
    setselectedResourse(offensedata?.resourceoffense);
    setSearch(offensedata?.searchbyuser);
    setSelectedStatus(offensedata.statusoffense);
    setCurrentPage(offensedata.pagenumberoffense ? offensedata.pagenumberoffense : 1);
    setSortOffensive(offensedata.sortvalueoffense);
  }, []);

  useEffect(() => {
    handlView();
  }, [sortoffensive, limitvalue]);

  //  const getLimitValue=()=>{

  //   setlimitvalue(limitvalueoffense)
  // }
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  // const getPageNumber=()=>{
  //   setCurrentPage(pagenumberoffense)
  // }
  // const getSortValue=()=>{
  //   setSortOffensive(sortvalueoffense)
  // }

  useEffect(() => {
    handlView();
    // getLimitValue();
    // getSortValue();
  }, [sortoffensive, limitvalue]);

  useEffect(() => {
    // getPageNumber();
    getTotalUnResolved();
  }, []);

  const getTotalUnResolved = () => {
    setLoader(true);
    let data = {
      status: selectedStatus ? selectedStatus : "",
      start_date: selectedfromdate ? selectedfromdate : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      page: currentPage,
      limit: limitvalue ? limitvalue : 10,
      searchKey: search,
      resource_id: selectedResourse?.value ? selectedResourse?.value : "",
      sortby: sortoffensive ? sortoffensive : "",
      key: "export",
    };
    //console.log(data,"offensive payload")
    makePostRequest(URLS.GET_ALL_RESOURCES_OFFENSIVE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setOffensiveAllDataShow(res.data.data);

          setLoader(false);
          //console.log(res,"offensive response show")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [counts, setCounts] = useState({ unresolved: 0, notify: 0 });

  useEffect(() => {
    const unresolvedCount = offensiveAllDataShow.filter((element) => element.status === "reported").length;
    const notifyCount = offensiveAllDataShow.filter((element) => element.status === "notify").length;
    let counts = {
      unresolved: unresolvedCount,
      notify: notifyCount,
    };

    setCounts(counts);

    //console.log(counts,"counts")
  }, [offensiveAllDataShow]);

  let sum = 0;
  sum = counts.unresolved + counts.notify;

  // console.log(sum,"sum")

  // useEffect(() => {
  //   const count = offensiveAllDataShow.filter(element => {
  //     if (element.status !="resolved") {
  //       return true;
  //     }

  //     return false;
  //   }).length;
  //   setUnresolvedCount(count);

  //   const countnotify = offensiveAllDataShow.filter(element => {
  //     if (element.status =="notify") {
  //       return true;
  //     }

  //     return false;
  //   }).length;

  //   setNotifyCount(countnotify)

  // },[offensiveAllDataShow])

  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      // status: selectedStatus ? selectedStatus : "",
      // start_date: selectedfromdate ? selectedfromdate : "",
      // end_date: selectedtodate? moment(selectedtodate).add(1,'d').format("YYYY-MM-DD"):"",
      // page: currentPage,
      // limit: limitvalue?limitvalue:10,
      // searchKey: search,
      // resource_id: selectedResourse?.value?selectedResourse?.value:"",
      // sortby:sortoffensive?sortoffensive:"",
      status: offensedata.statusoffense ? offensedata.statusoffense : "",
      start_date: offensedata.startDate ? moment(offensedata.startDate).format("YYYY-MM-DD") : "",
      end_date: offensedata.endDate ? moment(offensedata.endDate).format("YYYY-MM-DD") : "",
      page: offensedata.pagenumberoffense,
      limit: offensedata.limitvalueoffense ? offensedata.limitvalueoffense : 10,
      searchKey: search,
      //resource_id: selectedResourse?.value?selectedResourse?.value:"",
      resource_id: offensedata?.resourceoffense?.value ? offensedata?.resourceoffense?.value : "",
      sortby: offensedata.sortvalueoffense ? offensedata.sortvalueoffense : "",
      key: "export",
    };
    console.log(data,"export data .....................")
    makePostRequest(URLS.GET_ALL_RESOURCES_OFFENSIVE_EXPORT, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          console.log(res,"export response.....................")

          // setCount(res.data.count / 10);
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("OffensiveData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "REPORTED DATE",
              key: "REPORTED_DATE",
              width: 15,
            },
            {
              header: "REPORTED BY",
              key: "REPORTED_BY",
              width: 15,
            },
            {
              header: "REPORTED BY EMAIL",
              key: "REPORTED_BY_EMAIL",
              width: 15,
            },
            {
              header: "REPORTED BY PHONE",
              key: "REPORTED_BY_PHONE",
              width: 15,
            },
            {
              header: "RESOURCE TITLE",
              key: "RESOURCE",
              width: 45,
            },
            {
              header: "RESOURCE OWNER NAME",
              key: "RESOURCE_OWNER_NAME",
              width: 45,
            },
            {
              header: "RESOURCE OWNER EMAIL",
              key: "RESOURCE_OWNER_EMAIL",
              width: 45,
            },
            {
              header: "RESOURCE OWNER PHONE",
              key: "RESOURCE_OWNER_PHONE",
              width: 45,
            },

            {
              header: "REPORTED COMMENT",
              key: "REPORTED_COMMENT",
              width: 20,
            },
            //    {
            //     header:"MESSAGE TO CUSTOMER",
            //   key:'MESSAGE_TO_CUSTOMER',
            //   width:20
            //  },
            {
              header: "MESSAGE TO RESOURCE OWNER",
              key: "MESSAGE_TO_RESOURCE_OWNER",
              width: 20,
            },
            {
              header: "RESOURCE STATUS",
              key: "RESOURCE_STATUS",
              width: 18,
            },
            {
              header: "RESOURCE BLOCK STATUS",
              key: "RESOURCE_BLOCK_STATUS",
              width: 18,
            },
            
            {
              header: "If Block Message to Owner",
              key: "Block_Message_to_Owner",
              width: 18,
            },
            // {
            //   header:"STATUS",
            // key:'STATUS',
            // width:15
            // },
            {
              header: "ACTION TAKEN BY",
              key: "ACTION_TAKEN_BY",
              width: 15,
            },
          ];

          res.data?.data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REPORTED_DATE: moment(val?.created_at).format("MM-DD-YYYY"),
              REPORTED_BY: val?.reportedUser ? val?.reportedUser.firstName : "--",
              REPORTED_BY_EMAIL: val?.reportedUser ? val?.reportedUser.email : "--",
              REPORTED_BY_PHONE: val?.reportedUser ? val?.reportedUser.phone : "--",
              RESOURCE: val?.tbl_user_resource.resource_title ? val?.tbl_user_resource.resource_title : "--",
              RESOURCE_OWNER_NAME: val?.tbl_user_resource.tbl_user.firstName
                ? val?.tbl_user_resource.tbl_user.firstName
                : "--",
              RESOURCE_OWNER_EMAIL: val?.tbl_user_resource.tbl_user.email
                ? val?.tbl_user_resource.tbl_user.email
                : "--",
              RESOURCE_OWNER_PHONE: val?.tbl_user_resource.tbl_user.phone
                ? val?.tbl_user_resource.tbl_user.phone
                : "--",
              REPORTED_COMMENT: val?.comment ? val?.comment : "--",
              // MESSAGE_TO_CUSTOMER: val?.userComment ? val?.userComment : "--",
              MESSAGE_TO_RESOURCE_OWNER: val?.resourceComment ? val?.resourceComment : "--",
              RESOURCE_STATUS: val?.resource_block == "yes" ? "Resource Blocked" : "Resouce Unblocked",
              RESOURCE_STATUS:
                val.status === "resolved"
                  ? "Resolved"
                  : val.status === "reported"
                  ? "Reported"
                  : "Resource Owner Notified",
              RESOURCE_BLOCK_STATUS: val.resource_block === "yes" ? "Resource Blocked" : "Resource Unblocked",
              Block_Message_to_Owner: val?.resolved_remark ? val?.resolved_remark : "--",
              ACTION_TAKEN_BY: val?.tbl_admin_user ? val?.tbl_admin_user.name : "--",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Offensive.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusResolveReported = (row) => {
    setShowComment(row);
    setResolvedBy(row.resolvedby);
    if (showComment.status === "reported") {
      setIsOpen(true);
    } else if (showComment.status === "notify") {
      setIsOpen1(true);
    } else if (showComment.status === "resolved") {
      setIsOpen2(true);
    } else {
    }
  };
  useEffect(() => {
    // if(showComment.status === "reported"){
    //       setIsOpen(true);
    //     }
    //     else if(showComment.status === "notify"){
    //       setIsOpen1(true);
    //     }
    //     else if (showComment.status === "resolved"){
    //       setIsOpen2(true);
    //     }
    //     else{
    //     }
  }, [showComment]);

  //console.log(showResourceBlock,"button");
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {currentPage == 1
            ? index + 1
            : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
        </div>
      ),
    },

    {
      name: "Reported Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      // sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Resource Details",
      selector: (row) => row.tbl_user_resource.resource_title,
      grow: 2,
      width: "160px",
      minWidth: "160px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <p className="mb-1">
              <span className="sub-text float-start">{row.tbl_user_resource.resource_title}</span>
              {row?.tbl_user_resource?.is_deleted === "yes" ? (
                ""
              ) : (
                <Link to={{ pathname: `/editResource-page/${row.tbl_user_resource.id}`, state: dataToPass }}>
                  <button className="dropdown-item btn btn-md">
                    <em className="icon ni ni-edit"></em>
                  </button>
                </Link>
              )}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Reported By",
      selector: (row) => row.reportedUser?.firstName + row.reportedUser?.lastName,
      grow: 2,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <p className="mb-1">
              {" "}
              <span className="sub-text float-start">
                {row.reportedUser ? row.reportedUser?.firstName + " " + row.reportedUser?.lastName : "--"}
              </span>
              <Link to={`/view-customer-details/${row?.reportedUser?.id}`}>
                <button className="dropdown-item btn btn-md">
                  <em className="icon ni ni-eye"></em>
                </button>
              </Link>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
      grow: 2,
      // sortable:true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.comment}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      grow: 3,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text resolved-span">
              {row.status === "resolved" ? (
                <div style={{ color: "green" }}>Resolved </div>
              ) : row.status === "reported" ? (
                <div style={{ color: "red" }}>Reported </div>
              ) : (
                <div style={{ color: "orange" }}>Resource Owner Notified</div>
              )}
              {row.resource_block === "yes" ? <div>(Resource Blocked)</div> : ""}
            </span>
          </div>
        </div>
      ),
    },

    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   cell: (row, index) => (
    //     <>
    //       {row.status == "resolved" && (
    //         <div className="custom-controlss custom-switchss">
    //           <input style={{ display: "none" }} type="checkbox" className="custom-control-input" id={row.id} checked />
    //           {/* onClick={() => {
    // 				 	handleswitch(row.id, "reported", index)}}   */}
    //           <label className="custom-control-labelss" htmlFor={row.id}>
    //             {" "}
    //             {row.status}
    //           </label>
    //         </div>
    //       )}
    //       {row.status == "reported" && (
    //         <div className="custom-controlss custom-switchss">
    //           <input style={{ display: "none" }} type="checkbox" className="custom-control-input" id={row.id} />
    //           {/* onClick={() => {
    // 					handleswitch(row.id, "resolved", index)}}   */}
    //           <label className="custom-control-labelss" htmlFor={row.id}>
    //             {" "}
    //             {row.status}
    //           </label>
    //         </div>
    //       )}
    //     </>
    //   ),
    // },
    {
      name: "Action",
      selector: (row) => row.status,
      grow: 2,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <ul>
            <li>
              <button
                className="btn bg-white d-none d-sm-inline-flex"
                onClick={() => {
                  statusResolveReported(row);
                }}
              >
                <em className="icon ni ni-eye"></em>
              </button>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    // handlView();
  }, []);

  // console.log("selectedfromdate", selectedfromdate);

  const handlView = () => {
    setLoader(true);
    let data = {
      // status: selectedStatus ? selectedStatus : "",
      // start_date: selectedfromdate ? selectedfromdate : "",
      // end_date: selectedtodate? moment(selectedtodate).add(1,'d').format("YYYY-MM-DD"):"",
      // page: currentPage,
      // limit: limitvalue?limitvalue:10,
      // searchKey: search,
      // resource_id: selectedResourse?.value?selectedResourse?.value:"",
      // sortby:sortoffensive?sortoffensive:"",
      // key : ""

      status: offensedata.statusoffense ? offensedata.statusoffense : "",
      start_date: offensedata.startDate ? moment(offensedata.startDate).format("YYYY-MM-DD") : "",
      end_date: offensedata.endDate ? moment(offensedata.endDate).format("YYYY-MM-DD") : "",
      page: offensedata.pagenumberoffense,
      limit: offensedata.limitvalueoffense ? offensedata.limitvalueoffense : 10,
      searchKey: search,
      //resource_id: selectedResourse?.value?selectedResourse?.value:"",
      resource_id: offensedata?.resourceoffense?.value ? offensedata?.resourceoffense?.value : "",
      sortby: offensedata.sortvalueoffense ? offensedata.sortvalueoffense : "",
      key: "",
    };
    console.log(data, "offensive payload");
    makePostRequest(URLS.GET_ALL_RESOURCES_OFFENSIVE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setOffensiveAllData(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
          setLoader(false);
          console.log(res, "offensive response");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("Please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("Please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("End date should be greater than start date");
    } else {
      handlView();
      setCurrentPage(1);
      // setSelectedStatus("");
      // setSelectedfromDate("");
      // setSelectedToDate("");
    }
  };

  const handleClearData = () => {
    setSelectedStatus("");
    setSelectedfromDate("");
    setSelectedToDate("");
    setSearch("");
    setselectedResourse("");
    handlView();
    setSortOffensive("");
    setCurrentPage(1);
    setlimitvalue(10);

    let offensedata = JSON.parse(sessionStorage.getItem("offense")) || {};

    offensedata.limitvalueoffense = "";
    offensedata.pagenumberoffense = "";
    offensedata.sortvalueoffense = "";
    (offensedata.statusoffense = ""),
      (offensedata.resourceoffense = ""),
      (offensedata.searchbyuser = ""),
      (offensedata.startDate = "");
    offensedata.endDate = "";
    setClearState(!clearState);

    sessionStorage.setItem("offense", JSON.stringify(offensedata));
  };

  const clearfilter = () => {
    handleClearData();
  };
  useEffect(() => {
    handlView();
  }, [clearState]);
  // Save resolution comment =========
  const saveSwitch = (id, isSelected) => {
    //  alert("Hi");
    setShowResourceBlock(isSelected);
    let data = {
      id: id,
      resource_block: isSelected,
    };
  };
  const handleResolutioin = (e) => {
    setsaveResolution(e.target.value);
    setErrorRequirement("");
    setErrorExpressionText("");
    let obj = { ...OffensiveData };
    let str = e.target.value;
    obj.name = str;
    setOffensiveData({ ...obj });
  };

  const handleResourceComment = (e) => {
    setResourceComment(e.target.value);
  };
  const saveButton = () => {
    // if (saveResolution == "" && showResourceBlock == true ) {
    //   setErrorRequirement("Please enter message to customer.");
    // } else {
    let data;
    if (showComment.status === "reported") {
      data = {
        id: showComment.id,
        resolved_by: userAuth.id,
        userComment: saveResolution,
        resourceComment: resourceComment,
        status: "notify",
      };
    }
    if (showComment.status === "notify") {
      data = {
        id: showComment.id,
        resolved_by: userAuth.id,
        resource_block: showResourceBlock ? "yes" : "no",
        resolved_remark: saveResolution,
        status: "resolved",
      };
    }

    //console.log(data,"updateoffensive data")
    setLoader(true);
    makePostRequest(URLS.UPDATE_RESOURCES_OFFENSIVE, null, data, null).then((res) => {
      if (res.code === 200) {
        handlView();
        setsaveResolution("");
        toast.success(res.message);
        setLoader(false);
        getTotalUnResolved();
        setOffensiveData({});
      } else {
        toast.warn(res.message);
      }
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
    });
  };

  // console.log(showResourceBlock,saveResolution,"resourceblock","sresolu")
  // // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);

    sessionStorage.setItem(
      "offense",
      JSON.stringify({
        limitvalueoffense: limitvalue,
        pagenumberoffense: selectedPage.selected + 1,
        sortvalueoffense: sortoffensive,
        statusoffense: selectedStatus,
        resourceoffense: selectedResourse,
        searchbyuser: search,

        endDate: selectedtodate ? moment(selectedtodate).format("MM-DD-YYYY") : "",
        startDate: selectedfromdate ? moment(selectedfromdate).format("MM-DD-YYYY") : "",
      })
    );
  };
  // // console.log(currentPage, "current page");
  // // Fetch data based on the current page
  // const fetchData = () => {
  //   // Your code to fetch data from the server based on the currentPage
  //   // For example, you can make an API call or update the state with the new data
  //   let data = {
  //     status: selectedStatus ? selectedStatus : "",
  //     start_date: selectedfromdate ? selectedfromdate : "",
  //     end_date: selectedtodate? moment(selectedtodate).add(1,'d').format("YYYY-MM-DD"):"",
  //     page: currentPage,
  //     limit: limitvalue?limitvalue:10,
  //     searchKey: search,
  //     resource_id: selectedResourse?.value,
  //     sortby:sortoffensive?sortoffensive:"",
  //     key : ""
  //   };
  //   makePostRequest(URLS.GET_ALL_RESOURCES_OFFENSIVE, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         setOffensiveAllData(res.data.data);
  //         setCount(Math.ceil(res.data.count / parseInt(limitvalue?limitvalue:10)));
  //         setLoader(false);

  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  useEffect(() => {
    // fetchData();
    handlView();
  }, [currentPage]);
  // Render your component with pagination

  // Apply code for click outside and close popup
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRef = useRef(null);

  const displaySelect = (e) => {
    if (selectRef.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRef.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (events) => {
    if (newRef1.current && !newRef1.current.contains(events.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Offensive List"></Head>
      <Content>
        <ToastContainer />

        <h3 className="nk-block-title page-title mb5 pb-4"> Offense List ({sum} Unresolved)</h3>

        <Card className="card-bordered">
          <>
            {showComment.status === "reported" ? (
              <Modal isOpen={isOpen} toggle={openModal}>
                <ModalHeader
                  toggle={openModal}
                  close={
                    <button className="close" onClick={openModal}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Offense Details
                </ModalHeader>
                <ModalBody>
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <label>
                        {" "}
                        <strong>Reported Comment: </strong>
                      </label>
                      {offensiveAllData && offensiveAllData.length > 0 ? (
                        <p> {showComment.comment} </p>
                      ) : (
                        <CardTitle>
                          <h6 className="title">No Comment found</h6>
                        </CardTitle>
                      )}
                    </BlockHead>
                  </BlockHeadContent>

                  <div className="row">
                    <div className="mt-2 resolution-cmnt">
                      {/* <strong>Message to Customer</strong> */}
                      {/* <textarea
                        placeholder="Message To Customer"
                        value={saveResolution}
                        className="form-control-xl form-control no-resize"
                        onChange={handleResolutioin}
                      ></textarea> */}
                      {/* <span className="error"> {errorRequirement} </span>
                      <span className="error"> {expressionTextError} </span> */}

                      <div className="mt-2">
                        <strong>Message to Resource Owner</strong>
                        <textarea
                          placeholder="Message To Resource Owner"
                          value={resourceComment}
                          className="form-control-xl form-control no-resize"
                          onChange={handleResourceComment}
                        ></textarea>
                      </div>
                      {/* <span className="error"> {errorRequirement} </span>
                      <span className="error"> {expressionTextError} </span> */}
                    </div>
                    <div className="mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          saveButton();
                        }}
                      >
                        {resourceComment.length == 0 ? "Save & Resolve" : "Save & Notify"}
                      </button>
                      <button className="btn btn-light" style={{ marginLeft: "15px" }} onClick={cancelModal}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            ) : (
              <p> </p>
            )}
          </>

          <>
            {showComment.status === "notify" ? (
              <Modal isOpen={isOpen1} toggle={openModal1}>
                <ModalHeader
                  toggle={openModal1}
                  close={
                    <button className="close" onClick={openModal1}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Offense Details
                </ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div>
                      <strong>Reported Comment: </strong>
                      <p> {showComment.comment} </p>
                    </div>
                    <div>
                      {/* <strong>Message to Customer: </strong> */}
                      {/* <p> {showComment.userComment} </p> */}

                      <strong>Message to Resource Owner: </strong>
                      <p>{showComment.resourceComment ? showComment.resourceComment : "--"}</p>
                    </div>

                    <div className="mt-2 col-12">
                      <strong className="float-start">Do you want to block this resource?</strong>

                      <div className="custom-control custom-switch mx-2">
                        <input
                          type="checkbox"
                          value={showResourceBlock}
                          checked={showResourceBlock}
                          onChange={(e) => setShowResourceBlock(!showResourceBlock)}
                          className="custom-control-input"
                          id="latest-sale"
                        />
                        <label className="custom-control-label" for="latest-sale"></label>
                      </div>
                    </div>

                    {showResourceBlock == true ? (
                      <div className="mt-2 resolution-cmnt">
                        <strong>Message to Resource Owner</strong>
                        <textarea
                          placeholder="Message To Resource Owner"
                          value={saveResolution}
                          className="form-control-xl form-control no-resize"
                          onChange={handleResolutioin}
                        ></textarea>
                        {/* <span className="error"> {errorRequirement} </span>
                      <span className="error"> {expressionTextError} </span> */}

                        {/* <div className="mt-2 col-12">
                        <strong className="float-start">Do you want to block this resource?</strong>

                        <div className="custom-control custom-switch mx-2">
                          <input
                            type="checkbox"
                            value={showResourceBlock}
                            checked={showResourceBlock}
                            onChange={(e) => setShowResourceBlock(!showResourceBlock)}
                            className="custom-control-input"
                            id="latest-sale"
                          />
                          <label className="custom-control-label" for="latest-sale"></label>
                        </div>
                      </div> */}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          saveButton();
                        }}
                      >
                        Save
                      </button>
                      <button className="btn btn-light" style={{ marginLeft: "15px" }} onClick={cancelModal}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            ) : (
              <p> </p>
            )}
          </>
          <>
            {showComment.status === "resolved" ? (
              <Modal isOpen={isOpen2} toggle={openModal2}>
                <ModalHeader
                  toggle={openModal2}
                  close={
                    <button className="close" onClick={openModal2}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Offense Details
                </ModalHeader>
                <ModalBody>
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <label>
                        {" "}
                        <strong>Reported Comment: </strong>
                      </label>
                      {offensiveAllData && offensiveAllData.length > 0 ? (
                        <p> {showComment.comment} </p>
                      ) : (
                        <CardTitle>
                          <h6 className="title">No Comment found</h6>
                        </CardTitle>
                      )}

                      <div>
                        {/* <strong>Message to Customer: </strong> */}
                        {/* {offensiveAllData && offensiveAllData.length > 0 ? (
                          <p>  <p> {showComment.userComment} </p> </p>
                        ) : (
                          <CardTitle>
                            <h6 className="title">No Comment found</h6>
                          </CardTitle>
                        )} */}

                        {showComment.resourceComment != "" ? (
                          <div>
                            <strong>Message to Resource Owner: </strong>
                            <p>{showComment.resourceComment ? showComment.resourceComment : "--"}</p>
                          </div>
                        ) : (
                          ""
                        )}

                        {showComment.resourceComment == "" ? (
                          ""
                        ) : (
                          <>
                            <strong>Block Message to Resource Owner: </strong>
                            <p>
                              {showComment.resource_block == "yes" ? (
                                <p>Resource Blocked</p>
                              ) : (
                                <p>Resource Unblocked</p>
                              )}
                            </p>
                          </>
                        )}
                      </div>

                      <button
                        className="btn btn-light"
                        style={{ marginLeft: "15px", float: "right" }}
                        onClick={cancelModal}
                      >
                        Cancel
                      </button>
                    </BlockHead>
                  </BlockHeadContent>
                </ModalBody>
              </Modal>
            ) : (
              <p></p>
            )}
          </>

          <div className="card-inner relativeClass">
            <BlockHeadContent>
              <BlockHead size="lg">
                {loader ? (
                  <div className="overflowloader" id="preload">
                    <div className="circle-square" style={{ marginTop: "250px" }}>
                      <div className="red" style={{ background: "#AAC0CE" }}></div>
                      <div className="blue" style={{ background: "#265472" }}></div>
                      <div className="green" style={{ background: "#F19357" }}></div>
                      <div className="yellow" style={{ background: "#F0B285" }}></div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <h4 onClick={ShowHideSelect} className="cursorPointer">Filters <i className="fa fa-filter" aria-hidden="true"></i> </h4> */}
                    <div className="row pb-2 pt-2  align-items-center">
                      <div className="col-lg-4 ">
                        <div className="filtersidebutton">
                          <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                            Filters
                            <FaFilter />
                          </h5>
                          <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}>
                            Clear Filters
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-8 d-flex  justify-content-end">
                        <select
                          value={sortoffensive}
                          className="form-select"
                          style={{ width: "170px" }}
                          onChange={HandleSortOffensive}
                        >
                          <option value="">Sort By</option>
                          <option value="ASCDATE">Date Ascending</option>
                          <option value="DESCDATE">Date Descending</option>
                          <option value="ASCTITLE">Resource Title Ascending</option>
                          <option value="DESCTITLE">Resource Title Descending</option>
                          <option value="ASCSTATUS">Status Ascending</option>
                          <option value="DESCSTATUS">Status Descending</option>
                        </select>

                        <div>
                          <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                        </div>
                        <div>
                          <select
                            value={limitvalue}
                            className="form-select"
                            style={{ width: "85px" }}
                            onChange={HandleLimitValue}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div>
                          &nbsp; &nbsp;
                          <button className="btn btn-primary " onClick={ExportEcelFile}>
                            <em className="icon ni ni-download-cloud"></em>
                            <span>Export</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    {show && (
                      <div>
                        <div className="row pb-2">
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Start Date</strong>
                              </label>

                              {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                              <div className="form-control-wrap" ref={newRef}>
                                <div
                                  className="form-icon form-icon-left"
                                  onClick={() => setDatePickerShow(!datepickershow)}
                                >
                                  <span onClick={() => handleclickdatepickericon()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate ? new Date(selectedfromdate) : null}
                                  onChange={HandleFromDate}
                                  className="form-control date-picker"
                                  open={datepickershow}
                                  onFocus={() => setDatePickerShow(!datepickershow)}
                                />
                              </div>
                            </div>
                            <span className="error">{formDateError}</span>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>End Date</strong>
                              </label>
                              <div className="form-control-wrap" ref={newRef1}>
                                <div
                                  className="form-icon form-icon-left"
                                  onClick={() => setDatePickerShow1(!datepickershow1)}
                                >
                                  <span onClick={() => handleclickdatepickericon1()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate ? new Date(selectedtodate) : null}
                                  onChange={HandleToDate}
                                  className="form-control date-picker"
                                  open={datepickershow1}
                                  onFocus={() => setDatePickerShow1(!datepickershow1)}
                                />
                              </div>
                              {/* <input
                                type="date"
                                name="todate"
                                className="form-control"
                                value={selectedtodate}
                                onChange={HandleToDate}
                              ></input> */}
                            </div>
                            <span className="error">{toDateError}</span>
                          </div>

                          {/* <div className="row pb-2"> */}
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Resource Title</strong>
                              </label>
                              <div className="form-control-wrap paymetsession">
                                <i
                                  className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                                  onClick={displaySelect}
                                ></i>
                                <Select
                                  className="form-select"
                                  defaultValue={selectedResourse}
                                  // value={selectedResourse}
                                  onChange={handleResourceName}
                                  openMenuOnFocus={displayvalueSelectGraph}
                                  // onMenuScrollToBottom={handleMenuScroll}
                                  // options={resourceDropdown?.map((v) => ({ value: v?.resource_id, label: v?.tbl_user_resource?.resource_title }))}
                                  options={[
                                    { value: "", label: "All" },
                                    ...resourceDropdown?.map((v) => ({
                                      value: v?.resource_id,
                                      label: v?.tbl_user_resource?.resource_title,
                                    })),
                                  ]}
                                  ref={selectRef}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Status</strong>
                              </label>

                              <select value={selectedStatus} className="form-select" onChange={HandleStatus}>
                                <option value="">All</option>
                                <option value={"resolved"}>Resolved</option>
                                <option value={"reported"}>Reported</option>
                                <option value={"yes"}>Resource Blocked</option>
                                <option value={"notify"}>Resource Owner Notified</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Search by Reporting Customer Name</strong>
                              </label>
                              <Input
                                name="name"
                                placeholder="search..."
                                type="text"
                                value={search}
                                onChange={handleSearchByReportedName}
                              />
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label className="w-100 mb-0">&nbsp;</label>
                              <button className="btn btn-md btn-primary" type="submit" onClick={handleSearch}>
                                {" "}
                                Search{" "}
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                      </div>
                    )}
                    <div className="outerBox">
                      {offensiveAllData?.length == 0 ? (
                        <div>No Data Found</div>
                      ) : (
                        <ReactDataTable
                          data={offensiveAllData}
                          columns={columns}
                          className="nk-tb-list"
                          searchable={false}
                          setPageCount={setPageCount}
                          // pagination
                        />
                      )}

                      {offensiveAllData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </BlockHead>
            </BlockHeadContent>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};
export default ListOffensive;
