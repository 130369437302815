import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getUserDetailsFromToken =()=>{
    let token = sessionStorage.getItem('auth-token');
    if(token){
      const tokenParts = token.split('.');
      const tokenPayload = JSON.parse(atob(tokenParts[1]));
      let {userAuth} = tokenPayload;
      return userAuth;
     }
}

export function isValidFileUploaded(file) {
  const validExtensions = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "mpeg",
    "mp4",
    "mpeg",
    "aac",
    "csv",
    "msword",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "pdf",
    "vnd.ms-excel",
    "plain",
    "zip",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const fileExtension = file.type.split("/")[1];
  return validExtensions.includes(fileExtension);
}
export function checkUrl(string) {
  let givenURL;
  try {
    givenURL = new URL(string);
    console.log(givenURL)
    
    if(givenURL.href.indexOf(".")===-1)
       return false;

    if(givenURL.protocol==="http:")
      return false;
    
    if(givenURL.protocol==="https:")
      return true;
    
  } catch (error) {

    return false;
  }
}


export function toastifyMessage(message, type) {
  if (type === "success") return toast.success(message);
  if (type === "error") return toast.error(message);
  if (type === "warn") return toast.warn(message);
}

export function formatSize(sizeInKB) {
  const thresholdInKB = 1024;
  const thresholdInMB = 1024;

  if (sizeInKB < thresholdInKB) {
      return `${sizeInKB.toFixed(2)} KB`;
  } else if (sizeInKB < thresholdInMB * thresholdInKB) {
      const sizeInMB = sizeInKB / thresholdInKB;
      return `${sizeInMB.toFixed(2)} MB`;
  } else {
      const sizeInGB = sizeInKB / (thresholdInMB * thresholdInKB);
      return `${sizeInGB.toFixed(2)} GB`;
  }
}