import React, { useEffect,useState } from "react";
import menu from "./MenuData";
import Icon from "../../components/icon/Icon";
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
import { URLS } from "../../constants/urls";
import { makeGetRequest, makePostRequest } from "../../service/api";
import { getUserDetailsFromToken } from "../../service/auth";
import { ListInlineItem } from "reactstrap";
import { capital } from "../../utils/Utils";

const MenuHeading = ({ heading }) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{heading}</h6>
    </li>
  );
};

const MenuItem = ({ icon, link, text, sub, newTab, sidebarToggle, mobileView, badge, ...props }) => {
  let currentUrl;
  const toggleActionSidebar = (e) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e);
    }
  };

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const menuHeight = (el) => {
    var totalHeight = [];
    for (var i = 0; i < el.length; i++) {
      var margin =
        parseInt(window.getComputedStyle(el[i]).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).marginBottom.slice(0, -2));
      var padding =
        parseInt(window.getComputedStyle(el[i]).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).paddingBottom.slice(0, -2));
      var height = el[i].clientHeight + margin + padding;
      totalHeight.push(height);
    }
    totalHeight = totalHeight.reduce((sum, value) => (sum += value));
    return totalHeight;
  };

  const makeParentActive = (el, childHeight) => {
    let element = el.parentElement.parentElement.parentElement;
    let wrap = el.parentElement.parentElement;
    if (element.classList[0] === "nk-menu-item") {
      element.classList.add("active");
      const subMenuHeight = menuHeight(el.parentNode.children);
      wrap.style.height = subMenuHeight + childHeight - 50 + "px";
      makeParentActive(element);
    }
  };

  useEffect(() => {
    var element = document.getElementsByClassName("nk-menu-item active current-page");
    var arrayElement = [...element];

    arrayElement.forEach((dom) => {
      if (dom.parentElement.parentElement.parentElement.classList[0] === "nk-menu-item") {
        dom.parentElement.parentElement.parentElement.classList.add("active");
        const subMenuHeight = menuHeight(dom.parentNode.children);
        dom.parentElement.parentElement.style.height = subMenuHeight + "px";
        makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e) => {
    e.preventDefault();
    var self = e.target.closest(".nk-menu-toggle");
    var parent = self.parentElement;
    // console.log("parent",parent)
    var subMenu = self.nextSibling;
    var subMenuItem = subMenu.childNodes;
    var parentSiblings = parent.parentElement.childNodes;
    var parentMenu = parent.closest(".nk-menu-wrap");
    //For Sub Menu Height
    var subMenuHeight = menuHeight(subMenuItem);
    // Get parent elements
    const getParents = (el, parentSelector) => {
      parentSelector = document.querySelector(".nk-menu");
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      var parents = [];
      var p = el.parentNode;
      while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };
    var parentMenus = getParents(self);
    if (!parent.classList.contains("active")) {
      // For Parent Siblings
      for (var j = 0; j < parentSiblings.length; j++) {
        parentSiblings[j].classList.remove("active");
        if (typeof parentSiblings[j].childNodes[1] !== "undefined") {
          parentSiblings[j].childNodes[1].style.height = 0;
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains("sub-opened")) {
          parentMenu.classList.add("sub-opened");

          for (var l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== "undefined") {
              if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + "px";
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add("active");
      subMenu.style.height = subMenuHeight + "px";
    } else {
      parent.classList.remove("active");
      if (parentMenu !== null) {
        parentMenu.classList.remove("sub-opened");
        for (var k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== "undefined") {
            if (parentMenus[k].classList.contains("nk-menu-wrap")) {
              parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + "px";
            }
          }
        }
      }
      subMenu.style.height = 0;
    }
  };

  const menuItemClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link || (link == "/subscription-list") && currentUrl.includes("/view-subscriptionplan"),
  });
  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
      {newTab ? (
        <Link
          to={`${process.env.PUBLIC_URL + link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="nk-menu-link"
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
        </Link>
      ) : (
        <NavLink
          to={`${process.env.PUBLIC_URL + link}`}
          className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
          onClick={sub ? menuToggle : null}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </NavLink>
      )}
      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
        </div>
      ) : null}
    </li>
  );
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, ...props }) => {
  //let userAuthData = getUserDetailsFromToken();
  const [permissions, setPermissions]=useState([]);
  
  let userAuthData = getUserDetailsFromToken();
  const showCustomePermission = () => {
    let data = {
      role_id: userAuthData.role_id,
    };
    let permissionArr = [];
    makePostRequest(URLS.GET_ROLE_PERMISSIONS, null, data, null)
      .then((res) => {
        // console.log("permissions sub menu",res.data)
        if (res.code == 200) {
          let permissionRes = res.data;
          permissionRes &&
            permissionRes.length > 0 &&
            permissionRes.map((val, i) => {
              permissionArr.push(val.tbl_permission);
            });
          //return permissionArr;
          //localStorage.setItem("permissionName", JSON.stringify(permissionArr));
          setPermissions(permissionArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showCustomePermission();
  }, []);



  return (
    <ul className="nk-menu-sub" style={props.style}>
     
      {sub.map((item) =>
        userAuthData?.tbl_role?.role_name !== "Super Admin" && checkValueExists(item.text, "subMenu",permissions) === true ? (
          <MenuItem
            link={item.link}
            icon={item.icon}
            text={item.text}
            sub={item.subMenu}
            key={item.text}
            newTab={item.newTab}
            badge={item.badge}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        ) : (
          userAuthData?.tbl_role?.role_name === "Super Admin" && (
            <MenuItem
              link={item.link}
              icon={item.icon}
              text={item.text}
              sub={item.subMenu}
              key={item.text}
              newTab={item.newTab}
              badge={item.badge}
              sidebarToggle={sidebarToggle}
              mobileView={mobileView}
            />
          )
        )
      )}
    </ul>
  );
};

const Menu = ({ sidebarToggle, mobileView }) => {
  const [permissions, setPermissions]=useState([]);
  let userAuthData = getUserDetailsFromToken();
  const showCustomePermission = () => {
    let data = {
      role_id: userAuthData.role_id,
    };
    // console.log("menu payload ",data)
    let permissionArr = [];
    makePostRequest(URLS.GET_ROLE_PERMISSIONS, null, data, null)
      .then((res) => {
        // console.log("permissions  menu",res.data)
        if (res.code == 200) {
          let permissionRes = res.data;
          permissionRes &&
            permissionRes.length > 0 &&
            permissionRes.map((val, i) => {
              permissionArr.push(val.tbl_permission);
            });
            setPermissions(permissionArr);
          //return permissionArr;
          //localStorage.setItem("permissionName", JSON.stringify(permissionArr));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showCustomePermission();
  }, []);
  //  console.log("Menu",menu)
  return (
    <ul className="nk-menu">
     {userAuthData?.tbl_role?.role_name !== "Super Admin" &&
     <MenuItem
              // key={item.text}
              link="/"
              icon="dashlite"
              text="Dashboard"
              sidebarToggle={sidebarToggle}
              mobileView={mobileView}
            />
     
     }
      {menu.map((item) =>
        // item.heading ? (
        //   <MenuHeading heading={item.heading} key={item.heading} />
        // ) : (
        userAuthData?.tbl_role?.role_name !== "Super Admin" && checkValueExists(item.text, "menu",permissions) === true ? (
          <MenuItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            text={item.text}
            sub={item.subMenu}
            badge={item.badge}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        ) : (
          // )
          userAuthData?.tbl_role?.role_name === "Super Admin" && (
            <MenuItem
              key={item.text}
              link={item.link}
              icon={item.icon}
              text={item.text}
              sub={item.subMenu}
              badge={item.badge}
              sidebarToggle={sidebarToggle}
              mobileView={mobileView}
            />
          )
        )
      )}
    </ul>
  );
};

const checkValueExists = (value, menuname,permissionNameArr) => {
  //let permissionNameArr = localStorage.getItem("permissionName")
  //let permissionNameArr = localStorage.getItem("permissionName")
    //? JSON.parse(localStorage.getItem("permissionName"))
    //: [];
  // console.log("Permission", permissionNameArr);

  
  if (menuname === "menu") {
    let res =
      permissionNameArr &&
      permissionNameArr.length > 0 &&
      permissionNameArr.some((el) => capital(el.tbl_master_permission.name.replaceAll("_", " ")) == value);
      console.log("result ",res,value)
    return res;
  } else {
    let res =
      permissionNameArr &&
      permissionNameArr.length > 0 &&
      permissionNameArr.some((el) => el.permission_name.replaceAll("_", " ") == value);
      // console.log("result ",res)
    return res;
  }

};

export default Menu;
